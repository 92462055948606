import react, { useEffect, useState, forwardRef } from 'react';
import logo from '../../assets/logoblack.svg'
import Services from '../../services.js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { Link, useNavigate } from 'react-router-dom';

export const NewPassword = ({ }) => {
    let services = new Services()
    let navigate = useNavigate()
    let pageName = window.location.pathname;
    pageName = `${pageName}`.split('/')
    let _email = pageName[pageName.length - 1]
    let _token = pageName[pageName.length - 2]
    // pageName = pageName == '' ? 'dashboard'.toLowerCase() : pageName.split("/")[1]
    console.log()
    // let isValid = false;
    const [email, setEmail] = useState(_email);
    const [password, setPassword] = useState('');
    const [comfirmPassword, setPasswordComfirmation] = useState('');
    const [token, setToken] = useState(_token);
    const [load, setLoad] = useState(false);
    const [errors, setError] = useState([]);

    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);

    const Alert = forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  
    const handleClose = (event, reason) => {
      setOpen(false);
      setOpenError(false);
    };

    const resetPassword = async () => {
        setLoad(true)
        let isValid = false;
        if(password == comfirmPassword){
            if(password.toString().length < 8){
                setError(["Password should atleast have a length of 8 characters"])
            }
            else{
                isValid = true;
                setError([])
            }
        }else{
            setError(["Passwords don't match"])
        }

        if(isValid){
            let data = {
                "email": email,
                "token": token,
                "password": password,
                "c_password": comfirmPassword,
            }
            try{
                let response = await services.resetPassword(data)
                console.log(response)
                setOpenError(false);
                setOpen(true);
                navigate('/login')
            }catch(e){
                setOpenError(true);
                setOpen(false);
            }
        }
        setLoad(false)
    }
  


    return (
        <div className='auth-wrapper'>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Password Reset Successfully. Redirecting ...
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={openError}
                autoHideDuration={10000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    System Socket Error, Please Try Again
                </Alert>
            </Snackbar>
            <div className="auth-box">
                <div className="logo">

                    <img src={logo} alt="" width="65px" height="65px" className='my-2' />
                    <p className='text-center fw-bold'>ACCOUNT SETUP</p>
                    {errors.map(error => {
                        return (<p key={error} className='errorList text-wrap alert alert-danger'>{error}</p>)
                    })}
                </div>
                <div className="inputs">
                    <TextField disabled value={email} onChange={(e) => { setEmail(e.target.value) }} className='my-1' label="Email" variant="standard" />
                    <TextField type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className='my-1' label="Password" variant="standard" />
                    <TextField type="password" value={comfirmPassword} onChange={(e) => { setPasswordComfirmation(e.target.value) }} className='my-1' label="Confirm Password" variant="standard" />
                    {/* <input value={email} type="text" placeholder='Email' onInput={e => setEmail(e.target.value)} /> */}
                    {/* <input value={password} type="password" placeholder='Password' onInput={e => setPassword(e.target.value)} /> */}
                    <button type="button" className="btn btn-dark px-3 my-3" onClick={resetPassword}>
                        {
                            (load) ? (
                                <div className='d-flex justify-content-center align-items-center'>
                                    <CircularProgress disableShrink style={{ color: "white" }} size={24} />
                                </div>
                            ) : "Reset"
                        }
                    </button>
                    <Link to='/password-recovery-help' className='link-dark'>Help</Link>
                </div>
            </div>

            <p className='copy-right my-5'>All rights reserved &copy; | 2022 Haddypro</p>


        </div>
    )
}
