import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Services from '../../services.js';
import { useNavigate } from 'react-router-dom';
import { IoMdCall, IoIosSend } from 'react-icons/io'
import IconButton from '@mui/material/IconButton';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BiSync } from 'react-icons/bi';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import { StaticTimePicker } from '@mui/x-date-pickers';
import { Autocomplete, Chip, CircularProgress, FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
var calendar = require('calendar-month-array')
var sprintf = require('sprintf-js').sprintf


////////////////////// Constants ///////////////////////////
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

////////////////////////////////////////////////////////////
export const InquiryModal = ({ name = "", phone = "", email = "", date = "", message = "", isOpen = false, open, close, submitHandler }) => {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            onClose={close}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            keepMounted
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpen}>
                <Box sx={style}>
                    <form className='popup-form'>
                        <div className='package-form-header'>
                            <p>Message ({email})</p>
                        </div>
                        <div className='p-3 d-flex flex-column'>
                            <div className="d-flex ">
                                <div className="d-flex flex-column mx-3">
                                    <p>Name: <b>{name}</b></p>
                                    <p>Email: <b>{email}</b></p>
                                </div>
                                <div className="d-flex flex-column mx-3">
                                    <p>Phone: <b>{phone}</b></p>
                                    <p>Date: <b>{new Date(date).toDateString()}</b></p>
                                </div>
                            </div>
                            <p className='inquiery-paragraph w-100 h-100'>{message}</p>
                        </div>
                        <div className="w-100 d-flex align-items-center justify-content-end">
                            {/* <Button variant="text">Close</Button> */}
                            <button type="button" onClick={close} className="btn btn-light btn-sm px-3 text-nowrap mx-3">Close</button>
                            <a href={`tel:${phone}`}><button type="button" className="btn btn-dark btn-sm px-3 text-nowrap mx-1" onClick={submitHandler}><IoMdCall /> Call</button></a>
                            <a href={`mailto:${email}`}><button type="button" className="btn btn-dark btn-sm px-3 text-nowrap mx-3" onClick={submitHandler}><IoIosSend /> Reply</button></a>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export const AlertMessage = ({ title = "Confirmation", message = "", isOpen = false, open, close, onApprove }) => {
    const [loading, setLoading] = useState(false);
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            onClose={close}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            keepMounted
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpen}>
                <Box sx={style}>
                    <form className='popup-form'>
                        <div className='package-form-header'>
                            <p>{title}</p>
                        </div>
                        <div className='p-3 d-flex flex-column'>
                            <p className='w-100 h-100'>{message}</p>
                        </div>
                        <div className="w-100 d-flex align-items-center justify-content-end">
                            {/* <Button variant="text">Close</Button> */}
                            {
                                (loading == true) ? (
                                    <div className='px-3 text-nowrap mx-2'>
                                        <CircularProgress disableShrink style={{ color: "black" }} size={24} />
                                    </div>
                                ) : (
                                    <button type="button" className="btn btn-danger btn-sm px-3 text-nowrap mx-2" onClick={async () => { setLoading(true); await onApprove(); setLoading(false) }}>Yes</button>
                                )
                            }
                            <button type="button" onClick={close} className="btn btn-dark btn-sm px-3 text-nowrap mx-3">No</button>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export const AsyncCalendar = ({ serviceType, onDone, open, onClose, blockedTime = [
    new Date('2014-08-18T21:11:54'),
    new Date('2014-08-18T21:11:54'),
    new Date('2014-08-18T21:11:54'),
] }) => {
    // let services = new Services()
    const [dte, setDte] = useState(9)
    const [mnth, setMnth] = useState(8)
    const [yr, setYr] = useState(2022)

    const [weeks, setWeeks] = useState(calendar(new Date(yr, mnth, 0), {
        weekStartDay: 1,
        formatHeader: date => date.toString().slice(0, 2),
        formatDate: date => sprintf('%2d', date.getDate()),
        formatSiblingMonthDate: () => '  '
    }))

    // let daysOfWeeks = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"]
    let datesList = weeks

    const [canViewTime, setCanViewTime] = useState(serviceType == "Service")
    const [dates, setDates] = useState([
        // daysOfWeeks,
        ...datesList
    ])
    const [isReady, setIsReady] = useState(true)
    const [viewTime, setViewTime] = useState(false)
    const [selectedDates, setSelectedDates] = useState([])

    const appendTime = async (date) => {
        // console.log(date)
    }
    const handleMonthValue = async (date) => {
        // console.log(date)
    }

    useEffect(() => {
        const sync = async () => {
            // let response = awasetYearit services.getOrders();
            // response = response.data;
            // console.log(response)
        }
        sync()
    }, [])


    let _date = new Date().getDate()
    let [_month, setCurrentMonth] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleString('en-us', { month: 'long' }))
    let _year = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getYear()
    let currentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleString('en-us', { month: 'long' })

    const [month, setMonth] = useState(_month)
    const [year, setYear] = useState(yr)
    const [scheduledTime, setScheduledTime] = useState([])
    const [clickedDate, setClickedDate] = useState(null)
    const [startTime, setStartTime] = useState(".")
    const [endTime, setEndTime] = useState(".")

    const timeButtonClick = (tm) => {
        if (
            ((yr) == ((new Date().getFullYear()))) &&
            ((mnth) == ((new Date().getMonth() + 1))) &&
            (clickedDate) == ((new Date().getDate()))
        ) {
            if (
                ((tm) > ((new Date().getHours()) - 12))
            ) {
                let __time__ = [...scheduledTime]
                // if (__time__.includes(tm)) {
                //     __time__.pop(__time__.indexOf(tm))
                // } else {
                //     __time__.push(tm)
                // }
                __time__[0] = tm;
                setScheduledTime(__time__)
            }
        } else {
            let __time__ = [...scheduledTime]
            // if (__time__.includes(tm)) {
            //     __time__.pop(__time__.indexOf(tm))
            // } else {
            //     __time__.push(tm)
            // }
            __time__[0] = tm;
            setScheduledTime(__time__)
        }

        console.log()
        // console.log((new Date().getDate()))
    }

    const StartTimeHandler = () => {
        if (scheduledTime[0] != undefined) {
            let tm = `${clickedDate}-${mnth}-${yr} ${scheduledTime[0]}:00`
            setStartTime(tm);
            setScheduledTime([])
        }
    }
    const EndTimeHandler = () => {
        if (scheduledTime[0] != undefined) {
            let tm = `${clickedDate}-${mnth}-${yr} ${scheduledTime[0]}:00`
            setEndTime(tm);
            setScheduledTime([])
        }
    }

    const renderCalendar = () => {
        // setWeeks(
        //     calendar(new Date(yr, mnth, 0), {
        //         weekStartDay: 1,
        //         formatHeader: date => date.toString().slice(0, 2),
        //         formatDate: date => sprintf('%2d', date.getDate()),
        //         formatSiblingMonthDate: () => '  '
        //     })
        // )
        // console.log(yr, mnth, 0)
        // setMonth(new Date(yr, mnth, 0).toLocaleString('en-us', { month: 'long' }))
        // setYear(yr)
    }

    const nextMonth = (e) => {
        if (mnth < 12) {
            let mnth_ = mnth + 1;
            setMnth(mnth_)
            setMonth(new Date(yr, mnth_, 0).toLocaleString('en-us', { month: 'long' }))
            setWeeks(
                calendar(new Date(yr, mnth_, 0), {
                    weekStartDay: 1,
                    formatHeader: date => date.toString().slice(0, 2),
                    formatDate: date => sprintf('%2d', date.getDate()),
                    formatSiblingMonthDate: () => '  '
                })
            )
        }
    }

    const previousMonth = (e) => {
        if (mnth > 1) {
            let mnth_ = mnth - 1;
            setMnth(mnth_)
            setMonth(new Date(yr, mnth_, 0).toLocaleString('en-us', { month: 'long' }))
            setWeeks(
                calendar(new Date(yr, mnth_, 0), {
                    weekStartDay: 1,
                    formatHeader: date => date.toString().slice(0, 2),
                    formatDate: date => sprintf('%2d', date.getDate()),
                    formatSiblingMonthDate: () => '  '
                })
            )
        }
        // console.log(yr, mnth_)
    }

    const nextYear = (e) => {
        // if (yr < 2100) {
        //     // setYr(yr_)
        // }
        let yr_ = yr + 1;
        setYr(yr_)
        setMonth(new Date(yr_, mnth, 0).toLocaleString('en-us', { month: 'long' }))
        setWeeks(
            calendar(new Date(yr_, mnth, 0), {
                weekStartDay: 1,
                formatHeader: date => date.toString().slice(0, 2),
                formatDate: date => sprintf('%2d', date.getDate()),
                formatSiblingMonthDate: () => '  '
            })
        )
    }

    const previousYear = (e) => {
        // if (yr > 2022) {
        //     // setYr(yr_)
        // }
        let yr_ = yr - 1;
        setYr(yr_)
        // setMonth(new Date(yr_, mnth, 0).toLocaleString('en-us', { month: 'long' }))
        setWeeks(
            calendar(new Date(yr_, mnth, 0), {
                weekStartDay: 1,
                formatHeader: date => date.toString().slice(0, 2),
                formatDate: date => sprintf('%2d', date.getDate()),
                formatSiblingMonthDate: () => '  '
            })
        )
    }

    const schedule = (e, dt, replace = true) => {
        let _schedule = ""
        try {
            _schedule = `${yr}-${month}-${dt.trimRight().trimEnd().trimLeft()}`
        } catch (e) {
            _schedule = `${yr}-${month}-${dt}`
        }
        let _ = [...selectedDates]
        if (!selectedDates.includes(_schedule)) {
            _.push(_schedule)
            setSelectedDates(_)
        }
        else if ((selectedDates.includes(_schedule)) && replace) {
            _.pop(_schedule)
            setSelectedDates(_)
        }
        // console.log(selectedDates)
    }

    const openDate = (e, date) => {
        if (canViewTime) setViewTime(true)
        // appendTime(date)
        if (!canViewTime) schedule(e, date)
        setClickedDate(date)
        // console.log(serviceType == "Service")
        // console.log(date)
    }

    const saveTime = (e, date) => {
        // console.log(clickedDate)
        if ((startTime != ".") && (endTime != ".")) {
            schedule(e, clickedDate, false)
            // setViewTime(false)
            let data = {
                start: startTime,
                end: endTime,
            }
            onDone(data)
            onClose()
            console.log(data)
        }
    }

    const setTimeAndClose = () => {
        let dts = []
        selectedDates.forEach(dt => {
            dts.push(new Date(dt))
        })
        let _ = dts.sort((a,b) =>  new Date(b.date) - new Date(a.date));
        let data = {
            start: _[0],
            end: _[_.length - 1]
        }
        onDone(data)
        onClose()

    }

    return isReady ? (
        <div className='calendar-container'>
            <div className="calendar d-flex flex-row overflow-hidden">
                {
                    (viewTime)
                        ? (
                            <div className='flex-none w-100 d-flex justify-content-start px-4 align-items-center position-relative'>
                                <div className='flex-none w-100 d-flex flex-column justify-content-start align-items-center position-relative'>
                                    <div className='flex-none w-100 d-flex justify-content-start align-items-start position-relative'>
                                        <div className="clock-container">
                                            <div className="clock-inner-circle"></div>
                                            <div className="clock-center-circle"></div>
                                            <div className="clock-numbers">
                                                {
                                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                                                        .map((number, index) => {
                                                            let btnClassName = scheduledTime.includes(number) ? "btn isScheduledTime" : "btn";
                                                            let _btnClassName = ((number) <= ((new Date().getHours()) - 12) && ((clickedDate) == ((new Date().getDate()))) && ((yr) == ((new Date().getFullYear())))) ? "isDisabledTime" : "btn";
                                                            return (<div style={{
                                                                transform: `rotate(${(index + 1) * 30}deg) translate(50%, 0%)`
                                                            }} className="clock-button">
                                                                <div
                                                                    onClick={(e) => { timeButtonClick(number) }}
                                                                    className={`${btnClassName} ${_btnClassName}`}
                                                                    style={{
                                                                        transform: `rotate(${(index + 1) * -30}deg)`
                                                                    }} >
                                                                    {(number)}
                                                                </div>
                                                            </div>)
                                                        })
                                                }
                                                {/* {
                                            [
                                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                            ]
                                                .map((number, index) => {
                                                    return (<div style={{
                                                        transform: `rotate(${(index + 1)*5}deg) translate(50%, 0%)`
                                                    }} className="clock-button clock-coverage">
                                                        <div className="btn" style={{
                                                                transform: `rotate(${(index + 1) * -30}deg)`
                                                            }} >
                                                        </div>
                                                    </div>)
                                                })
                                        } */}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column">

                                            <div className="d-flex flex-column mx-4 px-4">
                                                <small className="m-0">1. Click your time on the clock</small>
                                                <small className="m-0">2. Choose between start and end time</small>
                                                <small className="m-0">3. Click on set</small>
                                            </div>
                                            <div className="fields mx-4 my-3 px-4 h-100 w-100 d-flex justify-content-between align-items-center">
                                                <TextField value={startTime} disabled label="Start Time" variant="standard" />
                                                <button onClick={(e) => { StartTimeHandler() }} className="btn btn-dark mx-2">Set</button>
                                            </div>
                                            <div className="fields mx-4 my-3 px-4 h-100 w-100 d-flex justify-content-between align-items-center">
                                                <TextField value={endTime} disabled label="End Time" variant="standard" />
                                                <button onClick={(e) => { EndTimeHandler() }} className="btn btn-dark mx-2">Set</button>
                                            </div>
                                            <div className="fields mx-4 my-3 px-4 h-100 w-100 d-flex justify-content-between align-items-center">
                                                <button onClick={(e) => { setStartTime("."); setEndTime("."); }} className="btn btn-light w-100">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fields w-100 d-flex align-items-end justify-content-end">
                                        <button className='btn btn-light mx-4' onClick={(e) => setViewTime(false)}>Back</button>
                                        <button className='btn btn-dark' onClick={(e) => saveTime()}>Done</button>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center position-absolute my-2 top-0 end-0'>
                                    <IconButton className='mx-2' onClick={(e) => setViewTime(false)} aria-label="delete">
                                        <AiFillCloseCircle />
                                    </IconButton>
                                </div>
                            </div>)
                        : (<div className='flex-row w-100 flex-none'>
                            <div className="calendar-header">
                                <p></p>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='calendar-header-btn-container'>
                                        <IconButton className='mx-2' onClick={(e) => { previousMonth(e); renderCalendar(); }} aria-label="delete">
                                            <BsArrowLeftCircle />
                                        </IconButton>
                                        <button className='mx-2'>{month}</button>
                                        <IconButton className='mx-2' onClick={(e) => { nextMonth(e); renderCalendar(); }} aria-label="next">
                                            <BsArrowRightCircle />
                                        </IconButton>
                                    </div>
                                    <div className='calendar-header-btn-container'>
                                        <IconButton className='mx-2' onClick={(e) => { previousYear(e); renderCalendar(); }} aria-label="delete">
                                            <BsArrowLeftCircle />
                                        </IconButton>
                                        <button className='mx-2'>{yr}</button>
                                        <IconButton className='mx-2' onClick={(e) => { nextYear(e); renderCalendar(); }} aria-label="next">
                                            <BsArrowRightCircle />
                                        </IconButton>
                                    </div>
                                    <IconButton className='mx-2' onClick={(e) => onClose()} aria-label="delete">
                                        <AiFillCloseCircle />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="calendar-body">
                                <div className="calendar-section">
                                    {
                                        weeks.map((week, week_index) => {
                                            return (
                                                <div className='weeks position-relative'>
                                                    {
                                                        week_index == 0 ? (<div className='position-absolute week-days-bg py-2'></div>) : null
                                                    }
                                                    {
                                                        week.map((day, date_index) => {
                                                            // console.log(_date)
                                                            let cls = "";
                                                            if ((+day < +_date) && (month == currentMonth) && (yr == new Date().getFullYear())) cls = "disabled "
                                                            if ((+day == (+_date)) && (month == currentMonth) && (yr == new Date().getFullYear())) cls = "current "
                                                            if (selectedDates.includes(`${yr}-${month}-${+day}`)) cls = "selected "
                                                            if ((selectedDates.includes(`${yr}-${month}-${+day}`)) && (+day == (+_date)) && (month == currentMonth) && (yr == new Date().getFullYear())) cls = "current-selected "
                                                            // selectedDates.map(dt_ => {
                                                            // })
                                                            // console.log(selectedDates, (`${yr}-${month}-${+day}`))
                                                            return week_index == 0 ? (
                                                                <button className={`weeks-days`}><b>{day}</b></button>
                                                            ) : (
                                                                <button disabled={((+day < +_date) && (month == currentMonth) && (yr == new Date().getFullYear()))} onClick={(e) => openDate(e, day)} className={`dates ${cls}`}>{day}</button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='calendar-bottom d-flex justify-content-end align-items-center'>
                                    <button type="button" onClick={(e) => onClose()} className="btn btn-light btn-sm my-2 px-3 text-nowrap mx-2">Close</button>
                                    {canViewTime ? null : (<button type="button" onClick={(e) => setTimeAndClose()} className="btn btn-dark btn-sm my-2 px-3 text-nowrap mx-2">Done</button>)}
                                </div>
                            </div>
                        </div>)
                }
            </div>
        </div>
    ) : null;
}

export default { InquiryModal, AlertMessage, AsyncCalendar };