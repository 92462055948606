import axios from 'axios';
import { ExportToCsv } from 'export-to-csv';

export default class Services {
    // Variables | Attributes
    token = "";
    isProduction = true
    URL = this.isProduction ? "https://api.haddyprotz.com" : "192.168.1.114:8000"
    // ///////////////////////////////////////////////////// //

    constructor() {
        // let storage = localStorage;
        this.authStatus = false;
        this.token = sessionStorage.getItem("token");
        (this.token == null)
            ? this.authStatus = false
            : this.authStatus = true;
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        }
        return this.authStatus
    }

    rerouteOnFail() {
    }

    async getPackages() {
        const response = await axios.get(
            `https://api.haddyprotz.com/api/packages`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async registerPackage(data) {
        const response = await axios.post(
            `${this.URL}/api/packages`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async sendInvitation(data) {
        const response = await axios.post(
            `${this.URL}/api/invitations`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getCharges() {
        const response = await axios.get(
            `${this.URL}/api/charges`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async tokenVerify(token) {
        const response = await axios.post(
            `${this.URL}/api/invitations/${token}`,
            {
                token: token,
            },
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getSpecService(id) {
        const response = await axios.get(
            `${this.URL}/api/services/${id}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getSpecPackage(id) {
        const response = await axios.get(
            `${this.URL}/api/packages/${id}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async updatePackage(data, id) {
        const response = await axios.put(
            `${this.URL}/api/packages/${id}`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async deletePackage(id) {
        const response = await axios.delete(
            `${this.URL}/api/packages/${id}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getPackage(name) { }

    async getEnvVars() {
        const response = await axios.get(
            `${this.URL}/api/env_variables`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async registerEnvironment(data) {
        const response = await axios.post(
            `${this.URL}/api/env_variables`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async deleteEnvironment(id) {
        const response = await axios.delete(
            `${this.URL}/api/env_variables/${id}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async updateEnvironment(data, id) {
        const response = await axios.put(
            `${this.URL}/api/env_variables/${id}`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getServices() {
        const response = await axios.get(
            `${this.URL}/api/services`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getServiceCategories() {
        const response = await axios.get(
            `${this.URL}/api/service_categories`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }
    async getPageContent(page_name) { }

    async getUsers() {
        const response = await axios.get(
            `${this.URL}/api/users`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getOrders() {
        const response = await axios.get(
            `${this.URL}/api/orders`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getItems() {
        const response = await axios.get(
            `${this.URL}/api/items`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getRecentOrders() {
        const response = await axios.get(
            `${this.URL}/api/recent_orders`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getEquipments() {
        const response = await axios.get(
            `${this.URL}/api/items`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async deleteEquipment(id) {
        const response = await axios.delete(
            `${this.URL}/api/items/${id}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async registerEquipment(data) {
        const response = await axios.post(
            `${this.URL}/api/items`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async updateEquipment(data, id) {
        const response = await axios.put(
            `${this.URL}/api/items/${id}`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getReceipt(page_name) { }

    async setOrder(page_name) { }

    async getWebContent(page_name) {
        const response = await axios.get(
            `${this.URL}/api/pages`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getTotalRevenue() {
        const response = await axios.get(
            `${this.URL}/api/total_revenue`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async setWebContent(data) {
        const response = await axios.post(
            `${this.URL}/api/pages`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async sendInquery(page_name) { }

    async registerService(data) {
        const response = await axios.post(
            `${this.URL}/api/services`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async updateService(data, id) {
        const response = await axios.put(
            `${this.URL}/api/services/${id}`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async deleteService(id) {
        const response = await axios.delete(
            `${this.URL}/api/services/${id}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async deleteInqery(id) {
        const response = await axios.delete(
            `${this.URL}/api/enquiries/${id}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getInquery() {
        const response = await axios.get(
            `${this.URL}/api/enquiries`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async getUnreadInquery() {
        const response = await axios.get(
            `${this.URL}/api/enquiries`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data.data;
        let count = 0;
        for (let i = 0; i < packages.length; i += 1) {
            let pkg = packages[i]
            if (pkg['status'] != true) count += 1
        }
        return count > 0 ? count : null
    }

    async getUncheckedOrders() {
        const response = await axios.get(
            `${this.URL}/api/orders`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data.data;
        let count = 0;
        for (let i = 0; i < packages.length; i += 1) {
            let pkg = packages[i]
            if (pkg['status'] == "pressed") count += 1
        }
        return count > 0 ? count : null
    }

    async getInvoices() {
        const response = await axios.get(
            `${this.URL}/api/invoices`,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        return response.data;
    }

    async placeOrder(data) {
        const response = await axios.post(
            `${this.URL}/api/orders`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        return response.data;
    }

    async viewInquery(data, id) {
        const response = await axios.put(
            `${this.URL}/api/enquiries/${id}`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async updateProfile(data) {
        const response = await axios.put(
            `${this.URL}/api/users/${data['email']}`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async login(email, password) {

        const userdata = await axios.post(`https://api.haddyprotz.com/api/login`, {
            email: email,
            password: password
        })
        const response = userdata.data;
        if (response['success'] == true) {
            localStorage.removeItem('uname');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('authority');
            localStorage.removeItem('avatar');
            localStorage.removeItem('first_name');
            localStorage.removeItem('last_name');
            localStorage.removeItem('phone');
            // ////////////////////////////////////////////////////////////////////////// //
            localStorage.setItem('email', email);
            localStorage.setItem('uname', response['data']['username']);
            sessionStorage.setItem('authority', response['data']['roles_id']);
            localStorage.setItem('avatar', response['data']['user_image']);
            localStorage.setItem('first_name', response['data']['first_name']);
            localStorage.setItem('last_name', response['data']['last_name']);
            localStorage.setItem('phone', response['data']['phone']);
            // localStorage.setItem('token', response['data']['token']);
            sessionStorage.setItem('token', response['data']['token']);
            if ((localStorage.getItem('sideNavOpen') == undefined) || (localStorage.getItem('sideNavOpen') == null)) {
                localStorage.setItem('sideNavOpen', 1);
            }
        }
        return response['success'];
    }

    async getProfile() {

    }
    async logout() {
        localStorage.removeItem('uname');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('authority');
        localStorage.removeItem('avatar');
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('phone');

        window.location.replace("/")

        window.location.reload(false);

        return true
    }

    async registerUser(data) {
        const response = await axios.post(
            `${this.URL}/api/register`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async registerUser(data) {
        const response = await axios.post(
            `${this.URL}/api/register`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    async resetPassword(data) {
        const response = await axios.post(
            `${this.URL}/api/pwd_change_link`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }
    async resetPasswordWithEmail(email) {
        const response = await axios.post(
            `${this.URL}/api/pwd_reset_link`,
            {
                email: email,
            },
            {
                headers: {
                    "Access-Control-Allow-Origin": '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            });
        const packages = response.data;
        return packages
    }

    commaSeparate(x) {
        let val = ''
        try {
            x = x.toString()
            for (let i = 0; i < x.length; i += 1) {
                let character = x[i]
                if (character != ",") val += character;
            }
        } catch (e) { }
        return val == "0" ? "0" : val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    removeComma(x) {
        let val = ''
        try {
            x = x.toString()
            for (let i = 0; i < x.length; i += 1) {
                let character = x[i]
                if (character != ",") val += character;
            }
        } catch (e) { }
        return val == "0" ? "0" : val;
    }

    generateToken(len = 7) {
        let characters = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
        let str = ""
        for (let i = 0; i < len; i += 1) {
            let index = Math.floor(Math.random() * (characters.length - 1))
            str += characters[index]
        }
        return str;
    }

    inPercent(x) {
        let val = ''
        x = x.toString()
        x = +x
        if (x < 100) {
            for (let i = 0; i < x.length; i += 1) {
                let character = x[i]
                if (character != "%") val += character;
            }
        }
        return val == "0" ? "0%" : `${val}%`;
    }

    async clearCache() {
        let routes = [
            "https://api.haddyprotz.com/clear-cache",
            "https://api.haddyprotz.com/route-cache",
            "https://api.haddyprotz.com/config-cache",
        ]
        routes.forEach(async (route) => {
            let response = await axios.get(
                route,
                {
                    headers: {
                        "Access-Control-Allow-Origin": '*',
                        'Authorization': `Bearer ${this.token}`
                    },
                }
            );
        })
    }

    async excelExport(title, fields, data) {
        var _data = [];
        
        for(let i = 0; i < data.length; i+=1){
            let dt = data[i]
            let newDt = {}
            for(let j = 0; j < fields.length; j+=1){
                let field = fields[j]
                newDt[field] = dt[j]
            }
            _data.push(newDt)
        }
        console.log(_data)
        
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(_data);
    }
}