import PackageTable from '../includes/Table'
import React, { useEffect, useState } from "react";
import Services from '../../services.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai'

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { $ } from 'react-jquery-plugin';
import { InquiryModal, AlertMessage } from '../includes/modals';
import Loader from '../includes/Loader';


const Inquiery = ({ callback }) => {
    let services = new Services()
    const navigate = useNavigate()
    const location = useLocation();
    const pageName = location.pathname;
    const [isLoaded, setLoad] = useState(false);
    const [openedName, setOpenedName] = useState(null);
    const [openedEmail, setOpenedEmail] = useState(null);
    const [openedPhone, setOpenedPhone] = useState(null);
    const [openedMessage, setOpenedMessage] = useState(null);
    const [openedDate, setOpenedDate] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [deleteId, setDeleteId] = useState(null);

    const [messages, setMessages] = useState([]);
    const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);
    const handleCloseCreatePackageModal = () => setOpenCreatePackageModal(false);
    const handleOpenCreatePackageModal = async () => setOpenCreatePackageModal(true);

    const [openAlertBox, setOpenAlertBox] = useState(false);
    const handleCloseAlertBox = () => {
        setOpenAlertBox(false);
        setDeleteId(null);
    }
    const handleOpenAlertBox = async (e, message) => {
        setAlertMessage(message);
        setOpenAlertBox(true);
    }

    const openInquiryModal = async (e, data) => {
        setOpenedName(`${data['full_name']}`);
        setOpenedEmail(`${data['email']}`);
        setOpenedPhone(`${data['phone']}`);
        setOpenedMessage(`${data['message']}`);
        setOpenedDate(`${data['created_at']}`);
        setOpenCreatePackageModal(true)
        await services.viewInquery({ "status": true }, data['id'])
        callback();
        let _messages = await services.getInquery()
        _messages = _messages.data;
        let read_data = []
        let unread_data = []
        _messages.forEach(msg => {
            if(msg['status'] == "0"){
                unread_data.push(msg)
            }else{
                read_data.push(msg)
            }
        })
        let _data = [...unread_data, ...read_data]
        // console.log(_messages)
        setMessages(_data)
    }

    const deleteInquiery = async (e, id) => {
        try {
            await services.deleteInqery(deleteId)
            window.location.reload()
        } catch (e) { }
        handleCloseAlertBox()
        // callback();
    }

    useEffect(() => {
        async function sync() {
            if (services.authStatus == false) {
                navigate('./login', { replace: true });
                return
            } else {
                if (messages.length == 0) {
                    let _messages = await services.getInquery()
                    _messages = _messages.data;
                    let read_data = []
                    let unread_data = []
                    _messages.forEach(msg => {
                        if(msg['status'] == "0"){
                            unread_data.push(msg)
                        }else{
                            read_data.push(msg)
                        }
                    })
                    let _data = [...unread_data, ...read_data]
                    // console.log(_messages)
                    setMessages(_data)
                }
            }
            setTimeout(() => {
                if (isLoaded == false) {
                    $("#packageTable").DataTable({
                        autoWidth: false,
                        destroy: true,
                        jQueryUI: true,
                        // dom: "rBftlip",
                        pageLength: 10,
                    })
                    setLoad(true);
                    // console.log(packa)
                }
            }, 250);
        }
        sync()
    }, [messages])

    return isLoaded ? (
        <div>
            <InquiryModal date={openedDate} email={openedEmail} message={openedMessage} phone={openedPhone} name={openedName} isOpen={openCreatePackageModal} close={handleCloseCreatePackageModal} open={handleOpenCreatePackageModal} />
            <AlertMessage onApprove={deleteInquiery} message={alertMessage} isOpen={openAlertBox} close={handleCloseAlertBox} open={handleOpenAlertBox} />
            <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
            </div>
            {/* <PackageTable tableTitle = "Packages"/> */}
            <div className="car">
                <table id='packageTable' className='stripe'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Message</th>
                            <th width="25%">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            messages && messages.map((message, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className='d-flex'>
                                        {`${message['full_name']}`}
                                        {
                                            (message['status'] == 1) ? (
                                                <p>: {message['email']}</p>
                                            ) : (
                                                <b>: ({message['email']})</b>
                                            )
                                        }
                                    </td>
                                    <td>{`${message['phone']}`}</td>
                                    {
                                        (message['status'] == 1) ? (
                                            <td className=''><p className='inquiry-text text-truncate'>{message['message']}</p></td>
                                        ) : (
                                            <td className=''><p className='inquiry-text text-truncate'><b>{message['message']}</b></p></td>
                                        )
                                    }
                                    <td width="25%">
                                        {
                                            (message['status'] == 1) ? (
                                                <button type="button" onClick={(e) => openInquiryModal(e, message)} className="btn btn-light  btn-sm px-3 mx-1">View</button>
                                            ) : (
                                                <button type="button" onClick={(e) => openInquiryModal(e, message)} className="btn btn-dark  btn-sm px-3 mx-1">View</button>
                                            )
                                        }
                                        <button type="button" onClick={(e) => { setDeleteId(message['id']); handleOpenAlertBox(e, `Are you sure you want to delete ${message['email']} message ?`); }} className="btn btn-danger  btn-sm px-3 mx-1">Delete</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    ) : (<Loader />)
}

export default Inquiery
