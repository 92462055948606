import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';
import react, { useEffect, useState, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { MdDashboard } from 'react-icons/md'
import { CgComponents } from 'react-icons/cg'
import { AiOutlineStock } from 'react-icons/ai'
import { MdInventory2 } from 'react-icons/md'
import { BsFillSpeakerFill } from 'react-icons/bs'
import { SiGoogleanalytics } from 'react-icons/si'
import { TbDoorEnter } from 'react-icons/tb'
import { HiDocumentReport } from 'react-icons/hi'
import { FaGlobeAsia } from 'react-icons/fa'
import { RiFootprintFill } from 'react-icons/ri'
import { IoMdMail, IoMdSettings } from 'react-icons/io'
import { GoPackage } from 'react-icons/go'
import { BiMenu } from 'react-icons/bi'
import { BsFillPersonFill } from 'react-icons/bs'
import { FaUsers } from 'react-icons/fa'

import Dashboard from './pages/Dashboard';
import Equipments from './pages/Equipments';
import Orders from './pages/Orders';
import Packages from './pages/Packages';
import Profile from './pages/Profile';
import Reports from './pages/Reports';
import Stock from './pages/Stock';
import TraceLog from './pages/TraceLog';
import Users from './pages/Users';
import ServicesPage from './pages/Services';
import Services from '../services.js';

import logo from '../assets/logo.svg'
import logoExtended from '../assets/prologo-w.png'
import Inquiery from './pages/Inquiery';
import Web from './pages/Web';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CreateUser from './pages/registerUser';
import Analytics from './pages/Analytics';
import Nav from '../nav';
import Settings from './pages/Setting';
import Accessibility from './pages/accessibility';
import Schedule from './pages/schedule';
import Invoices from './pages/invoices';

const PrimaryView = () => {
  let services = new Services()
  // let location = useResolvedPath()
  // let navigate = useNavigate()
  const nav = Nav()
  let pageName = window.location.pathname;
  pageName = `${pageName}`.replace('/', '').toUpperCase()
  pageName = pageName == '' ? 'dashboard'.toUpperCase() : pageName.split("/")[0]
  if ((localStorage.getItem('sideNavOpen') == undefined) || (localStorage.getItem('sideNavOpen') == null)) {
    localStorage.setItem('sideNavOpen', 1);
  }
  const [toggledIndex, isToggled] = useState(+localStorage.getItem('sideNavOpen'));
  const [avatorOpened, isAvatorOpened] = useState(false)
  const [navIndex, isNavChanged] = useState(Object.keys(nav).indexOf(pageName.toLowerCase()))
  const [isOnline, setIsOnline] = useState(true)
  const [isMessage, setIsMessage] = useState(false)
  const [fname, setFname] = useState('...')
  const [lname, setLname] = useState('...')
  const [uname, setUname] = useState('...')
  const [email, setEmail] = useState('...')
  const [unreadInquiry, setUnreadInquiry] = useState(null)
  const [uncheckedOrders, setUncheckedOrders] = useState(null)
  const [snackbarMessage, setSnackBarMessage] = useState("")
  const [authority, setAuthority] = useState(0)

  const isToggledOpen = () => {
    if (toggledIndex == 0) {
      isToggled(1)
      localStorage.setItem('sideNavOpen', 1);
    }
    else {
      isToggled(0)
      localStorage.setItem('sideNavOpen', 0);
    }
  }

  useEffect(() => {
    const sync = async () => {
      setUname(localStorage.getItem('uname'))
      setEmail(localStorage.getItem('email'))
      setFname(localStorage.getItem('first_name'))
      setLname(localStorage.getItem('last_name'))
      setAuthority(+(sessionStorage.getItem('authority')))

      window.addEventListener("online", (event) => {
        setIsOnline(true)
        console.log(event)
      });
      window.addEventListener("offline", (event) => {
        setIsOnline(false)
        console.log(event)
      });
      let count = await services.getUnreadInquery();
      setUnreadInquiry(count)
      let _count = await services.getUncheckedOrders();
      _count = (_count == 0) ? null : _count;
      setUncheckedOrders(_count)
    }
    sync()
  })

  const getLatest = async () => {
    console.log("Hello")
    let count = await services.getUnreadInquery();
    count = (count == 0) ? null : count;
    setUnreadInquiry(count)
    let _count = await services.getUncheckedOrders();
    _count = (_count == 0) ? null : _count;
    setUncheckedOrders(_count)
  }

  const avatorToggle = () => {
    isAvatorOpened(!avatorOpened);
  }
  const navClicked = (index) => {
    isNavChanged(index)
    // console.log(navIndex);
  }

  const [open, setOpen] = useState(false);

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleCloseIsMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsMessage(false);
  };

  const signOut = async () => {
    // navigate("/");
    setOpen(true)
    await services.logout()
  }

  const openSnack = (message) => {
    console.log(message)
    setIsMessage(true);
    setSnackBarMessage(message)
  }
  // openSnack()
  return (
    <Stack sx={{ width: '100%' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Logedout, Redirecting...
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
        open={!isOnline}
        autoHideDuration={10000}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Logedout, Redirecting...
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
        open={isMessage}
        autoHideDuration={10000}
        onClose={handleCloseIsMessage}>
        <Alert onClose={handleCloseIsMessage} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className='outer-wrapper'>
        <Router>
          <div className={avatorOpened ? 'profile-box active' : 'profile-box'}>
            <div className="avator"></div>
            <div className="detail-box">
              <p className='fw-bold'>{uname}</p>
              <p>{email}</p>
            </div>

            <Link to="/profile" className='profile-btn m-auto text-center'>
              PROFILE
            </Link>
          </div>
          <div className='wrapper d-flex'>
            <section className='left-section'>
              <div className={toggledIndex === 0 ? 'side-nav ' : 'side-nav active'}>
                <div className="logo-container">
                  {
                    (toggledIndex == 0) ? (
                      <img src={logo} alt="" width="60px" height="60px" />
                    ) : (
                      <img src={logoExtended} alt="" height="60px" />
                    )
                  }
                </div>
                <div className="navigation-section">
                  <ul>
                    <li className='outer-nav-menu active'>
                      <div className="outer-nav-li">
                        Navigations
                      </div>
                      <div className='navigation-group'>
                        <ul>
                          {
                            Object.keys(nav).map((_nav, index) => {
                              let isAllowed = false;
                              try { isAllowed = nav[_nav]['authority'].includes(authority); } catch (e) { }
                              return isAllowed ? (
                                <li
                                  key={index}
                                  onClick={() => navClicked(index)}
                                  className={navIndex === Object.keys(nav).indexOf(_nav) ? 'inner-nav-menu active' : 'inner-nav-menu'}>
                                  {((_nav == "orders") && (uncheckedOrders != null)) ? (<span className='count'>{uncheckedOrders}</span>) : null}
                                  {((_nav == "inquiry") && (unreadInquiry != null)) ? (<span className='count'>{unreadInquiry}</span>) : null}
                                  <Link className='nav-li' to={nav[_nav]['link']} key={index}>
                                    <span>{nav[_nav]['icon']}</span>
                                    <span className='nav-title'>{nav[_nav]['name']}</span>
                                  </Link>
                                </li>
                              ) : null
                            })
                          }
                        </ul>
                      </div>
                    </li>
                    {
                      ([1].includes(authority)) ? (
                        <li className='outer-nav-menu'>
                          {
                            (toggledIndex === 1) ? (
                              <div className="outer-nav-li">
                                Others
                              </div>
                            ) : <div className="outer-nav-li my-5"></div>
                          }
                          <ul>
                            <li
                              onClick={() => navClicked(-1)}
                              className={navIndex === -1 ? 'inner-nav-menu active' : 'inner-nav-menu'}
                            >
                              <Link className='nav-li' to="/settings" >
                                <span>
                                  <IoMdSettings className='react-icons' size="21px" />
                                </span>
                                <span className='nav-title'>Settings</span>
                              </Link>
                            </li>
                          </ul>
                        </li>
                      ) : null
                    }

                  </ul>
                </div>
              </div>
            </section>
            <section className='right-section'>
              <div className="top-nav d-flex justify-content-between align-items-center">
                <div className='d-flex align-items-center'>
                  <div className="toogle-icon">
                    <BiMenu size="40px" onClick={(e) => isToggledOpen()} />
                  </div>
                  <h5 className='m-auto mx-4'>{pageName}</h5>
                </div>
                <div className="avator-section">
                  <button type="button" className="logout-btn bg-dark" onClick={signOut}>Sign Out</button>
                  <div className="avator-layout">
                    <div className="status-box"></div>
                    <div className="avator d-flex align-items-center justify-content-center" onClick={() => avatorToggle()}>
                      <p className='m-auto'>{fname[0].toUpperCase()}{lname[0].toUpperCase()}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-section">

                <Routes>
                  <Route path='/' element={<Dashboard />} />
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/inquiry' element={<Inquiery callback={getLatest} />} />
                  <Route path='/services' element={<ServicesPage />} />
                  <Route path='/equipments' element={<Equipments />} />
                  <Route path='/orders' element={<Orders />} />
                  <Route path='/packages' element={<Packages />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/reports' element={<Reports />} />
                  <Route path='/inventory' element={<Stock />} />
                  <Route path='/invoices' element={<Invoices />} />
                  <Route path='/tracelog' element={<TraceLog />} />
                  <Route path='/users' element={<Users />} />
                  <Route path='/users/new' element={<CreateUser />} />
                  <Route path='/users/accessibility' element={<Accessibility />} />
                  <Route path='/users/new/manual' element={<CreateUser pannel='manual' onSuccess={
                    (e, user) => {
                      console.log("Hello")
                      openSnack(`${user} registered successfully`)
                    }
                  } />} />
                  <Route path='/users/new/invite' element={<CreateUser pannel='invite' />} />
                  <Route path='/users/new/upload' element={<CreateUser pannel='upload' />} />
                  <Route path='/web' element={<Web />} />
                  <Route path='/analytics' element={<Analytics />} />
                  <Route path='/settings' element={<Settings />} />
                  <Route path='/schedule' element={<Schedule />} />
                </Routes>
              </div>
              <footer className='d-flex justify-content-between mt-5 py-2'>
                <small>All rights reserved @haddypro ©2022</small>
                <small>Property of <a target="#" href="https://nexent.dev">Nexent</a></small>
              </footer>
            </section>

          </div>
        </Router>
      </div>
    </Stack>
  )
}
export default PrimaryView
