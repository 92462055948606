import { IconButton, TextField } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { AiFillCamera } from 'react-icons/ai'
import Services from '../../services'

const Profile = () => {

  const services = new Services();

  const [fname, setFName] = useState("")
  const [sname, setSName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [role, setRole] = useState("")
  const [uname, setUname] = useState("")
  const [avatar, setAvatar] = useState("")

  useEffect(() => {
    let _email = localStorage.getItem('email');
    let _uname = localStorage.getItem('uname');
    let _authority = sessionStorage.getItem('authority');
    let _avatar = localStorage.getItem('avatar');
    let _first_name = localStorage.getItem('first_name');
    let _last_name = localStorage.getItem('last_name');
    let _phone = localStorage.getItem('phone');
    let _role = "";

    if (_authority == "1") _role = "Admin";
    if (_authority == "2") _role = "Staff";
    if (_authority == "3") _role = "Accountant";
    if (_authority == "4") _role = "Staff";

    setFName(_first_name);
    setSName(_last_name);
    setEmail(_email);
    setPhone(_phone);
    setRole(_role);
    setUname(_uname);
    setAvatar(_avatar);
  }, [])

  const updateProfile = async () => {
    let data = {
      first_name: fname,
      last_name: sname,
      email: email,
      phone: phone,
      username: uname,
    }

    await services.updateProfile(data)

  }

  const undoChange = async () => {
    let _email = localStorage.getItem('email');
    let _uname = localStorage.getItem('uname');
    let _authority = sessionStorage.getItem('authority');
    let _avatar = localStorage.getItem('avatar');
    let _first_name = localStorage.getItem('first_name');
    let _last_name = localStorage.getItem('last_name');
    let _phone = localStorage.getItem('phone');
    let _role = "";

    if (_authority == "1") _role = "Admin";
    if (_authority == "2") _role = "Staff";
    if (_authority == "3") _role = "Accountant";
    if (_authority == "4") _role = "Staff";

    setFName(_first_name);
    setSName(_last_name);
    setEmail(_email);
    setPhone(_phone);
    setRole(_role);
    setUname(_uname);
    setAvatar(_avatar);

  }

  return (
    <div className="profile-container d-flex flex-column justify-content-start align-items-center w-100">
      <div className="avatar d-flex align-items-center justify-content-center">
        <IconButton>
          <AiFillCamera color='white' size={34}/>
        </IconButton>
      </div>
      <form className='d-flex flex-column my-3'>
        <div className="d-flex">
          <div className="fields my-4 d-flex flex-column">
            <TextField value={uname} onChange={(e) => { setUname(e.target.value) }} id="standard-basic" className='my-2 mx-4' label="First Name" variant="standard" />
            <TextField value={fname} onChange={(e) => { setFName(e.target.value) }} id="standard-basic" className='my-2 mx-4' label="First Name" variant="standard" />
            <TextField value={phone} onChange={(e) => { setPhone(e.target.value) }} id="standard-basic" className='my-2 mx-4' label="Phone Number" variant="standard" />
          </div>
          <div className="fields my-4 d-flex flex-column">
            <TextField value={email} disabled onChange={(e) => { setEmail(e.target.value) }} id="standard-basic" className='my-2 mx-4' label="Email Address" variant="standard" />
            <TextField value={sname} onChange={(e) => { setSName(e.target.value) }} id="standard-basic" className='my-2 mx-4' label="Second Name" variant="standard" />
            <TextField value={role} disabled onChange={(e) => { setRole(e.target.value) }} id="standard-basic" className='my-2 mx-4' label="Role" variant="standard" />
          </div>
        </div>
          <div className="fields my-4 d-flex flex-row align-items-center justify-content-center">
            <button onClick={(e)=>{undoChange()}} type="button" className="btn btn-dark mx-4 w-100" >Undo</button>
            <button onClick={(e)=>{updateProfile()}} type="button" className="btn btn-light mx-4 w-100" >Update</button>
          </div>
      </form>
    </div>
  )
}

export default Profile
