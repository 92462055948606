import './App.css';
import './Calendar.css';
import { Login } from './components/pages/Login';
import PrimaryView from './components/PrimaryView';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './services.js';
import UnauthView from './components/UnauthView';


function App() {

  let services = new Services()
  console.log();


  if(services.token == null){
    return <UnauthView/>
  }else{
    return <PrimaryView />
  }
}

export default App;
