import PackageTable from '../includes/Table'
import React, { useEffect, useState } from "react";
import Services from '../../services.js';
import { useNavigate } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai'

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { $ } from 'react-jquery-plugin';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Loader from '../includes/Loader';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { Autocomplete, Chip, CircularProgress, FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import { AlertMessage } from '../includes/modals';

const Packages = () => {
  let services = new Services()
  const navigate = useNavigate()
  const [isLoaded, setLoaded] = useState(false);
  const [packages, setPackages] = useState([]);

  // ///////////////////////////////////////////////////////////////////////// //
  const [autofillEqipmentValue, setAutofillEqipmentValue] = useState([]);
  const [autofilleq, setAutofilleq] = useState('');
  const [autofillEquipments, setAutofillEqipments] = useState([]);
  const [eqQuantity, setEqQuantity] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [price, setPrice] = useState('');
  const [packageName, setPackageName] = useState('');
  const [packageQuantity, setPackageQuantity] = useState(1);
  const [packageCode, setPackageCode] = useState('');
  const [charges, setCharges] = useState('/ day');
  const [chargesList, setChargesList] = useState([]);
  const [chargesId, setChargesId] = useState(null);
  const [isPublic, setIsPublic] = useState(true);
  const [categoriesObject, setCategoriesObject] = useState({
    "pasystem": "PA System",
    "lightinganddimming": "Lighting & Dimming",
    "photography": "Photography & Videography",
    "advertisement": "Advertisement",
    "recordingstudio": "Recording Studio",
  });
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [loadSendingPackage, setLoadSendingPackage] = useState(null);
  const [updatingId, setUpdatingId] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  // ///////////////////////////////////////////////////////////////////////// //
  const [alertMessage, setAlertMessage] = useState(null);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const handleCloseAlertBox = () => {
    setOpenAlertBox(false);
    setCategoryId(null)
  }
  const handleOpenAlertBox = async (e, message, id) => {
    setAlertMessage(message)
    setOpenAlertBox(true);
    setDeletingId(id)
    // let _packages = await services.getPackages()
    // _packages = _packages;
    // console.log(_packages)
    // setPackages(_packages)
  }
  // ///////////////////////////////////////////////////////////////////////// //

  useEffect(() => {
    async function sync() {
      if (services.authStatus == false) {
        navigate('./login', { replace: true });
        return
      } else {
        if (packages.length == 0) {
          let _packages = await services.getPackages()
          _packages = _packages.data;
          setPackages(_packages)
          let _charges = await services.getCharges()
          _charges = _charges.data;
          setChargesList(_charges)
          let _items = await services.getItems()
          _items = _items.data;
          let items = []
          for (let i = 0; i < _items.length; i += 1) {
            items.push({ 'label': _items[i]['item_name'], 'id': _items[i]['id'] })
          }
          setAutofillEqipmentValue(items)
        }
      }
      setLoaded(true);
      setTimeout(() => {
        if (isLoaded == false) {
          $("#packageTable").DataTable({
            autoWidth: false,
            destroy: true,
            // dom: "rBftlip",
            pageLength: 10,
          })
          // console.log(packa)
        }
      }, 250);
    }
    $("#packageTable").dataTable().next()
    sync()
  }, [packages])

  const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);
  const handleOpenCreatePackageModal = async () => {
    setOpenCreatePackageModal(true);
  };

  const handleCloseCreatePackageModal = () => {
    setOpenCreatePackageModal(false);
    setAutofillEqipments([])
    setDiscount("0")
    setPrice("")
    setPackageName("")
    setPackageQuantity("1")
    setPackageCode("")
    setCharges("/ day")
    setChargesId(null)
    setIsPublic(true)
    setUpdatingId(null)
  }

  const handleDeleteEquipment = (e, id) => {
    let _equipments = [...autofillEquipments]
    _equipments.forEach((eq, index) => {
      if (eq['id'] == id) {
        _equipments.pop(_equipments[index])
      }
    })
    setAutofillEqipments(_equipments)
    console.log(_equipments)
  }

  const addEquipment = () => {
    if (autofilleq['label'] == undefined) return;
    let _equipments = [...autofillEquipments]
    _equipments.push({ item_name: autofilleq['label'], item_qty: +eqQuantity, item_id: autofilleq['id'], id: services.generateToken() })
    setAutofillEqipments(_equipments)
    console.log(_equipments)
  }

  const submitPackage = async () => {
    setLoadSendingPackage(true)
    let _price = ""
    for (let i = 0; i < price.length; i += 1) {
      if (price[i] != ",") _price += price[i]
    }
    let data = {
      package_name: packageName,
      qty: packageQuantity,
      price_tzs: _price,
      package_category: category,//{ 'id': category, 'label': categoriesObject[category] },
      discount: discount,
      package_code: packageCode,
      item_obj: autofillEquipments,
      charges_id: charges,
      public: isPublic,
    }
    try {
      if (updatingId != null) {
        let response = await services.updatePackage(data, updatingId)
      }
      else {
        let response = await services.registerPackage(data)
      }
    } catch (e) { }
    setLoadSendingPackage(false)
    // handleCloseCreatePackageModal()
  }

  const categoryTypeHandler = async (e, id) => {
    let value = e.target.value;
    setCategory(value)
    console.log(value)
    setCategoryId(value)
  }
  const chargesTypeHandler = async (e, id) => {
    let value = e.target.value;
    setCharges(value)
    console.log(value)
    setChargesId(value)
  }

  const viewPackage = async (data) => {
    handleOpenCreatePackageModal(true)
    setAutofillEqipments([...data['items']])
    setDiscount("0")
    setPrice(services.commaSeparate(data['price']))
    setPackageName(data['package_name'])
    setPackageQuantity(data['qty'])
    setPackageCode(data['package_code'])
    setCharges("/ day")
    setIsPublic(false)
    console.log(data)
  }

  const deletePackage = async () => {
    try {
      let response = await services.deletePackage(deletingId);
      setDeletingId(null);
      let _packages = await services.getPackages()
      _packages = _packages.data;
      setPackages(_packages)
    } catch (e) { }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return isLoaded ? (
    <div>
      <AlertMessage onApprove={deletePackage} message={alertMessage} isOpen={openAlertBox} close={handleCloseAlertBox} open={handleOpenAlertBox} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCreatePackageModal}
        onClose={handleCloseCreatePackageModal}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        keepMounted
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCreatePackageModal}>
          <Box sx={style}>
            <form className='popup-form pform'>
              <div className='package-form-header'>
                {
                  (updatingId == null) ? (
                    <p>Create Package</p>
                  ) : (
                    <p>Update Package</p>
                  )
                }
              </div>
              <div className='d-flex'>
                <div className='pform-container-1 p-3 d-flex flex-column'>
                  <TextField value={packageName} onChange={(e) => { setPackageName(e.target.value) }} id="standard-basic" className='my-2' label="Package Name" variant="standard" />
                  <Select
                    className='my-2 py-2'
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={category}
                    onChange={categoryTypeHandler}
                    label="Service Category"
                    variant="standard"
                  >
                    <MenuItem value="">
                      <em>Choose Service Category</em>
                    </MenuItem>
                    {
                      Object.keys(categoriesObject).map(type => (<MenuItem value={type}>{categoriesObject[type]}</MenuItem>))
                    }
                  </Select>
                  <TextField value={packageQuantity} onChange={(e) => { setPackageQuantity(e.target.value) }} id="standard-basic" className='my-2' type="number" label="Quantity" variant="standard" />
                  {/* <TextField value={charges} onChange={(e) => { setCharges(e.target.value) }} id="standard-basic" className='my-2' label="Charges Rate (/hour)" variant="standard" /> */}
                  <Select
                    className='my-2 py-2'
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={charges}
                    onChange={chargesTypeHandler}
                    label="Charges Rate"
                    variant="standard"
                  >
                    <MenuItem value="">
                      <em>Choose Service Category</em>
                    </MenuItem>
                    {
                      chargesList.map(charge => (<MenuItem value={charge['id']}>{charge['charges']}</MenuItem>))
                    }
                  </Select>
                  <TextField pattern="^[0-9]*" value={price} onChange={(e) => {
                    let val = e.target.value.replace(',', '')
                    setPrice(services.commaSeparate(val))
                  }} id="standard-basic" className='my-2' label="Service Price (TSh)" variant="standard" />
                  <TextField
                    type='number'
                    value={discount}
                    onChange={(e) => {
                      let val = e.target.value
                      if (+(val) >= 0 && +(val) <= 100) setDiscount(e.target.value)
                    }}
                    autoComplete={""}
                    id="standard-basic"
                    className='my-2'
                    label="Discount %"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    variant="standard" />
                  <TextField value={packageCode} onChange={(e) => { setPackageCode(e.target.value) }} id="standard-basic" className='my-2' type="text" label="Package Code" variant="standard" />
                </div>
                <div className='pform-container-2 p-3 d-flex flex-column'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={autofillEqipmentValue}
                      variant="standard"
                      className='my-3 w-100'
                      // value={autofillEqipmentValue}
                      onChange={(e, value) => { setAutofilleq(value) }}
                      renderInput={(params) => <TextField {...params} label="Equipments" />}
                    />
                    <TextField defaultValue={eqQuantity} onChange={(e) => { setEqQuantity(e.target.value) }} InputProps={{ inputProps: { min: 1 } }} sx={{ width: 100 }} id="standard-basic" className='my-2 mx-3' type="number" label="Qty" />
                    <button onClick={addEquipment} type="button" className="btn btn-light btn-sm px-3 text-nowrap mx-1">Add</button>
                  </div>
                  <FormControlLabel control={<Switch onChange={(e) => { setIsPublic(e.target.checked) }} checked={isPublic} />} label="Public" />
                  <div className='package-chip-area'>
                    {
                      autofillEquipments.map((eqpmnt, index) => {
                        return (
                          <Chip className="m-2" label={`${eqpmnt['item_qty']} • ${eqpmnt['item_name']}`} onDelete={(e) => handleDeleteEquipment(e, eqpmnt['id'])} />
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center justify-content-end">
                {/* <Button variant="text">Close</Button> */}
                <button onClick={(e) => { handleCloseCreatePackageModal() }} type="button" className="btn btn-light btn-sm px-3 text-nowrap mx-3">Cancel</button>
                <button onClick={submitPackage} type="button" className="btn btn-dark btn-sm px-3 text-nowrap mx-3">
                  {
                    (loadSendingPackage) ? (
                      <CircularProgress disableShrink style={{ color: "white" }} size={24} />
                    ) : (updatingId == null ? "Create" : "Update")
                  }
                </button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
      <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
        <section className='d-flex justify-content-end'>
          <button type="button" onClick={handleOpenCreatePackageModal} className="btn btn-dark btn-sm px-3 text-nowrap">Register new package</button>
        </section>
        <section className='memo-section'>
          <div className="memo-container pakages">
            <div className="memo-title">Total Packages</div>
            <div className="memo-data">
              {packages.length}
            </div>
          </div>
        </section>
      </div>
      {/* <PackageTable tableTitle = "Packages"/> */}
      <table id='packageTable' className='stripe' width="100%">
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>ID</th>
            <th>Price</th>
            <th width="25%">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            (packages != null) ?
              (packages && packages.map((pakg, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{pakg['package_image']}</td>
                  <td>{`${pakg['package_name']}`}</td>
                  <td>{pakg['package_code']}</td>
                  <td>{pakg['price']}</td>
                  <td width="25%">
                    <button onClick={(e) => { viewPackage(pakg); setUpdatingId(pakg['id']) }} type="button" className="btn btn-dark  btn-sm px-2 mx-1">
                      View / Update
                    </button>
                    <button onClick={(e) => { setDeletingId(pakg['id']); handleOpenAlertBox(e, `Are you sure you want to delete ${pakg['package_name']} package ?`, pakg['id']) }} type="button" className="btn btn-danger  btn-sm px-3 mx-1">Delete</button>
                  </td>
                </tr>
              )
              )
              ) : null
          }
        </tbody>
      </table>
    </div>
  ) : (<Loader />)
}

export default Packages
