import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Services from '../../services.js';
import { useNavigate } from 'react-router-dom';
import { Chip, CircularProgress, IconButton } from '@mui/material';
import { BsCalendarDateFill } from 'react-icons/bs'
import { AsyncCalendar } from './modals.js';
import { MdTry } from 'react-icons/md';

const OrderModel = ({ isOpen = false, open, close, submitHandler }) => {

    let services = new Services()
    const navigate = useNavigate()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    const [orderTypes, setOrderTypes] = useState([
        "Service",
        "Package",
    ]);

    const [servicesList, setServiceList] = useState([]);
    const [packagesList, setPackageList] = useState([]);
    const [orderType, setOrderType] = useState('');

    const [pkg, setPackage] = useState('');
    const [service, setService] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');

    const [isService, setIsService] = useState(false);
    const [isPackage, setIsPackage] = useState(false);
    const [isCalendar, setIsCalendar] = useState(true);
    const [dateTime, setDateTime] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const [calenderOpened, setCalenderOpened] = useState(false)


    const orderTypeHandler = async (event) => {
        setOrderType(event.target.value);
        console.log(orderType)
        if (orderType == "Service") {
            setIsService(true);
            setIsPackage(false);
        }
        else if (orderType == "Package") {
            setIsPackage(true);
            setIsService(false);
        }
        else {
            setIsPackage(false);
            setIsService(false);
        }
    };

    const serviceTypeHandler = (event) => {
        setService(event.target.value);
    }
    const packageTypeHandler = (event) => {
        setPackage(event.target.value);
    }

    useEffect(() => {
        async function sync() {
            if (orderType == "Service") {
                setIsService(true);
                setIsPackage(false);
                let _services = await services.getServices();
                _services = _services.data
                setServiceList(_services)
            }
            else if (orderType == "Package") {
                setIsPackage(true);
                setIsService(false);
                try {
                    let _packages = await services.getPackages();
                    _packages = _packages.data
                    setPackageList(_packages)
                } catch (e) { }
            }
            else {
                setIsPackage(false);
                setIsService(false);
            }
        }
        sync();
    }, [orderType])

    const handleDelete = () => {

    };

    const closeDelete = () => {
        setCalenderOpened(false)
    };

    const setDate = () => {
        setCalenderOpened(true)
    };

    const setupDateTime = (time) => {
        setDateTime(time)

    };

    const submit = async () => {
        let data = {
            "packages": pkg,
            "services": service,
            "date": dateTime,
        }
        console.log(dateTime)
        setIsLoading(true)
        try{
            await submitHandler(data)
        }catch(e){}
        setIsLoading(false)
        setPackage("")
        setService("")
        setDateTime([])
    };

    return (
        <div>
            {(calenderOpened == true) ? (<AsyncCalendar serviceType={orderType} onDone={(e) => { setupDateTime(e) }} onClose={closeDelete} />) : null}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpen}
                onClose={(e) => { close(); setOrderType(''); }}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                keepMounted
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpen}>
                    <Box sx={style}>
                        <form className='popup-form'>
                            <div className='package-form-header'>
                                <p>Place Order</p>
                            </div>
                            <div className='p-3 d-flex flex-column justify-content-center align-items-center'>
                                <Select
                                    className='my-2 w-100'
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={orderType}
                                    onChange={orderTypeHandler}
                                    label="Order Type"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        orderTypes.map(type => (<MenuItem value={type}>{type}</MenuItem>))
                                    }
                                </Select>
                                {(isService) ? (
                                    <Select
                                        className='my-2 w-100'
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={service}
                                        onChange={serviceTypeHandler}
                                        label="Order Type"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            servicesList.map(type => (<MenuItem value={type['id']}>{type['service_type']}</MenuItem>))
                                        }
                                    </Select>
                                ) : null
                                }
                                {(isPackage) ? (
                                    <Select
                                        className='my-2 w-100'
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={pkg}
                                        onChange={packageTypeHandler}
                                        label="Order Type"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            packagesList.map(type => (<MenuItem value={type['package_code']}>{type['package_name']}</MenuItem>))
                                        }
                                    </Select>
                                ) : null
                                }
                                {(isPackage || isService) ? (

                                    (scheduleDate == '') ? (
                                        <div className='d-flex align-items-center flex-column-reverse'>
                                            <div className=' w-10 d-flex flex-row align-items-center justify-content-center'>
                                                <p>{dateTime['start']}</p>
                                                <p className="mx-3">to</p>
                                                <p>{dateTime['end']}</p>
                                            </div>
                                            <IconButton onClick={(e) => setDate(e)} aria-label="delete">
                                                <BsCalendarDateFill />
                                            </IconButton>
                                        </div>
                                    ) : (
                                        <Chip label={scheduleDate} onDelete={handleDelete} />
                                    )

                                ) : null
                                }
                                {(isService == false && isPackage == false && isCalendar == false) ? (
                                    <p>Please Choose Order Type</p>
                                ) : null
                                }

                                {/* <TextField id="standard-basic" className='my-2' label="Equipment Name (Cannon DSLR D3000)" variant="standard" />
                            <TextField id="standard-basic" className='my-2' label="Equipment Type (Camera)" variant="standard" />
                            <TextField id="standard-basic" className='my-2' type="number" label="Quantity" variant="standard" />
                            <TextField id="standard-basic" className='my-2' type="number" label="Equipment Code" variant="standard" /> */}
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-end">
                                {/* <Button variant="text">Close</Button> */}
                                <button type="button" onClick={(e) => { close(); setOrderType(''); }} className="btn btn-light btn-sm px-3 text-nowrap mx-3">Cancel</button>
                                {
                                    (isLoading == true) ? (
                                        <div className='px-3 text-nowrap mx-2'>
                                            <CircularProgress disableShrink style={{ color: "black" }} size={24} />
                                        </div>
                                    ) : (
                                        <button type="button" onClick={submit} className="btn btn-dark btn-sm px-3 text-nowrap mx-3">Create</button>
                                    )
                                }
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default OrderModel;