import React, { useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Services from "../../services";

const locales = {
    "en-US": require("date-fns/locale/en-US")
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const events = []

const services = new Services();

const Schedule = () => {
    const [event, setEvent] = useState({ title: "", start: "", end: "" })
    const [allEvents, setAllEvents] = useState(events)

    const handleEvent = () => {
        setAllEvents([...allEvents, event])
    }

    const selectHandler = (e) => {
        console.log(e)
    }


    useEffect(() => {
        const sync = async () => {
            let response = await services.getOrders()
            response = response.data
            let evnt = [];
            // console.log(response)
            for (let i = 0; i < response.length; i += 1) {
                let dta = response[i]
                evnt.push({
                    allDay: false,
                    "title": dta['products']["order_description"],
                    "start": new Date(dta['products']["start_time"]),
                    "end": new Date(dta['products']["end_time"])
                })
                // console.log(dta['products']['start_time'])
            }
            setAllEvents(evnt)
            console.log(response)
        }
        sync()
    }, [])
    return (
        <div className="my-3">
            <Calendar
                localizer={localizer}
                events={allEvents}
                startAccessor="start"
                endAccessor="end"
                onSelectSlot={selectHandler}
                style={{
                    height: 500
                }}
            />
        </div>
    );
}

export default Schedule;