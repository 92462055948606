import react, { useEffect, useState, forwardRef } from 'react';
import logo from '../../assets/logoblack.svg'
import Services from '../../services.js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { BrowserRouter as Router, Routes, Route, Link, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

export const Login = ({ }) => {
    let services = new Services()
    let navigate = useNavigate()

    let email_saved = localStorage.getItem("email") ?? "";

    // let isValid = false;
    const [email, setEmail] = useState(email_saved);
    const [password, setPassword] = useState('');
    const [load, setLoad] = useState(false);
    const [errors, setError] = useState([]);

    const checkUser = async () => {
        setLoad(true)
        // isValid = true;
        setError([])
        try {
            const isValid = await services.login(email, password)
            setLoad(false)
            if(isValid){
                setOpen(true);
                setOpenError(false);
                navigate('/')
            }else{
                setOpen(false);
                setOpenError(true);
            }
            if (isValid) window.location.reload(false);
        } catch (e) {
            setLoad(false)
            let message = e.response.data.message;
            if (message == "Unauthorised.") {
                setError([`${message} Please Login`])
                setOpenError(true);
                setOpen(false);
            }
            // setError()
        }
        // console.log(isValid)


    }

    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);

    const Alert = forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  
    const handleClose = (event, reason) => {
      setOpen(false);
      setOpenError(false);
    };
  


    return (
        <div className='auth-wrapper'>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Authenticated. Redirecting ...
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={openError}
                autoHideDuration={10000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Authentication Error, Please Try Again
                </Alert>
            </Snackbar>
            <div className="auth-box">
                <div className="logo">

                    <img src={logo} alt="" width="65px" height="65px" className='my-2' />
                    <p className='text-center fw-bold'>STAFF LOGIN</p>
                    {/* {errors.map(error => {
                        return (<p key={error} className='errorList'>{error}</p>)
                    })} */}
                </div>
                <div className="inputs">
                    <TextField value={email} onChange={(e) => { setEmail(e.target.value) }} className='my-1' label="Email Address" variant="standard" />
                    <TextField type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className='my-1' label="Password" variant="standard" />
                    {/* <input value={email} type="text" placeholder='Email' onInput={e => setEmail(e.target.value)} /> */}
                    {/* <input value={password} type="password" placeholder='Password' onInput={e => setPassword(e.target.value)} /> */}
                    <button type="button" className="btn btn-dark px-3 my-3" onClick={checkUser}>
                        {
                            (load) ? (
                                <div className='d-flex justify-content-center align-items-center'>
                                    <CircularProgress disableShrink style={{ color: "white" }} size={24} />
                                </div>
                            ) : "Login"
                        }
                    </button>
                    <Link to='/password-recovery' className='link-dark'>forgot your password?</Link>
                </div>
            </div>

            <p className='copy-right my-5'>All rights reserved &copy; | 2022 Haddypro</p>


        </div>
    )
}
