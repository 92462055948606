import React, { useEffect, useState } from "react";
import Services from '../../services.js';
import { useNavigate } from 'react-router-dom';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { $ } from 'react-jquery-plugin';
import Loader from "../includes/Loader.js";
import OrderModel from "../includes/orderModel.js";
import OrderPlacerModel from "../includes/orderplace.js";
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, BarController, CategoryScale, BarElement } from 'chart.js';
import { AlertMessage } from "../includes/modals.js";
import DataTable from "../includes/tabledt.js";

const Dashboard = () => {
  let services = new Services()
  // let time = new Date()
  const navigate = useNavigate()
  const [isLoaded, setLoaded] = useState(false);
  const [packages, setPackages] = useState([]);
  const [loaded, setIsLoaded] = useState(false);
  const [recentOrders, setRecentOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [authority, setAuthority] = useState(0)
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);
  const handleCloseCreatePackageModal = () => setOpenCreatePackageModal(false);
  const handleOpenCreatePackageModal = async () => setOpenCreatePackageModal(true);

  const [analyticsData, setAnalyticsData] = useState(
    {}
  );


  const columns = [
    { field: 'date', headerName: 'Date', sortable: true },
    { field: 'token', headerName: 'Token' },
    { field: 'name', headerName: 'Name' },
    { field: 'price', headerName: 'Price (TSh)', type: 'number' },
    { field: 'paid', headerName: 'Paid (TSh)', type: 'number' },
    { field: 'status', headerName: 'Order Status' },
    { field: 'action', headerName: 'Action' },
    // {
    //   field: 'fullName', headerName: 'Full name', description: 'This column has a value getter and is not sortable.', sortable: false,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  // const rows = [
  //   { date: 1, token: 'Snow', name: 'Jon', price: 35, paid: 10, status: "placed", action: (<button onClick={(e) => { approve() }} type="button" className="btn btn-dark  btn-sm px-3">Instant Approval</button>) },
  // ];


  const analytics = async () => {
    console.log("Metric")
    const ctx = document.getElementById('myChart').getContext('2d');
    var chartExist = Chart.getChart("myChart");
    if (chartExist != undefined) {
      chartExist.destroy();
    }
    // ///////////////////////////////////////////////////// //
    let data = {}
    try {
      let _orders = await services.getOrders()
      _orders = _orders.data;

      let months = [
        "January", "February",
        "March", "April",
        "May", "June",
        "July", "August",
        "September", "October",
        "November", "December"
      ]
      months.forEach(mnth => {
        data[`${mnth}-${new Date().getFullYear()}`] = 0;//{price: 0, count: 0};//{count: 0, price: 0]
        // data[`${mnth}-${new Date().getFullYear()}`] = {price: 0, count: 0};//{count: 0, price: 0]
      })
      _orders.forEach(_order => {
        let dt = new Date(_order['created_at'])
        let _dt = `${dt.toLocaleString('en-us', { month: 'long' })}-${dt.getFullYear()}`
        if (Object.keys(data).includes(_dt)) {
          data[_dt] += 1
          // data[_dt]['count'] += 1
          // data[_dt]['price'] += 1
        } else {
          data[_dt] = 1
          // data[_dt]['count'] = 1
          // data[_dt]['price'] = 1
        }
      });

      setAnalyticsData(data)
      console.log(data)
      console.log(_orders)
    } catch (e) {
      console.log(e)
    }
    // ///////////////////////////////////////////////////// //
    $('#myModal').modal('show');
    // console.log(document.getElementById('myChart').getContext('2d'))
    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: Object.keys(data),
        datasets: [{
          label: '# of Votes',
          data: Object.values(data),
          borderWidth: 2.5,
          borderColor: '#ffa500',
          tension: 0.5,
          fill: true,
        }]
      },
      scales: {
        x: {
          type: 'linear'
        },
        y: {
          type: 'linear'
        }
      }
    })
    myChart.destroy();
    myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: Object.keys(data),
        datasets: [{
          label: '# of Votes',
          data: Object.values(data),
          borderWidth: 2.5,
          borderColor: '#ffa500',
          tension: 0.5,
          fill: true,
        }]
      },
      scales: {
        x: {
          type: 'linear'
        },
        y: {
          type: 'linear'
        }
      }
    })
    console.log("Loading chart")
  }

  const approve = async (order) => {

  }

  useEffect(() => {
    setAuthority(+(sessionStorage.getItem('authority')))
    async function sync() {
      // let val = await services.getPackages("")
      if (services.authStatus == false) {
        navigate('./login', { replace: true });
      }
      else {
        if (recentOrders.length == 0) {
          // ///////////////////////////////////////////////////// //
          try {
            let _recentOrders = await services.getRecentOrders()
            _recentOrders = _recentOrders.data;
            let _orders = []
            _recentOrders.forEach(order => {
              _orders.push({
                date: new Date(order['created_at']).toDateString(),
                token: order['order_token'],
                name: order['client_name'],
                price: order['price'],
                paid: order['discount'],
                status: order['status'],
                action: (<button onClick={(e) => { approve(order) }} type="button" className="btn btn-dark  btn-sm px-3">Instant Approval</button>)
              })
            })
            setRecentOrders(_orders);
            // console.log(_recentOrders)
          } catch (e) {
            console.log(e)
          }
          // ///////////////////////////////////////////////////// //
          try {
            let _totalRevenue = await services.getTotalRevenue()
            _totalRevenue = _totalRevenue.data;
            setTotalRevenue(_totalRevenue)
          } catch (e) { }
          // ///////////////////////////////////////////////////// //
          try {
            if (packages.length == 0) {
              let _packages = await services.getPackages()
              _packages = _packages.data;
              setPackages(_packages)
            }
          } catch (e) { }
          // ///////////////////////////////////////////////////// //
          try {
            if (packages.length == 0) {
              let _users = await services.getUsers()
              _users = _users.data;
              setUsers(_users)
            }
          } catch (e) { }
          setIsLoaded(true)
        }
      }
      setTimeout(async () => {
        if (isLoaded == false) {
          $("#recentOrdersTable").DataTable({
            autoWidth: false,
            destroy: true,
            // dom: "rBftlip",
            pageLength: 10,
          })
          setLoaded(true);
          // console.log(recentOrders)
          if ([1].includes(authority)) {
            // await analytics();
          }
        }
      }, 500);
      setIsLoaded(true)
    }
    if (!loaded) sync();

  }, [loaded]);



  return isLoaded ? (
    <>
      {/* <AlertMessage onApprove={deletePackage} message={alertMessage} isOpen={openAlertBox} close={handleCloseAlertBox} open={handleOpenAlertBox} /> */}
      <OrderPlacerModel isOpen={openCreatePackageModal} close={handleCloseCreatePackageModal} open={handleOpenCreatePackageModal} />
      <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
        <section className='d-flex justify-content-end'>
          <button onClick={handleOpenCreatePackageModal} type="button" className="btn btn-dark btn-sm px-3 text-nowrap">Place Order</button>
        </section>
        <section className='memo-section'>
          {
            ([1].includes(authority)) ? (
              <div className="memo-container revenue">
                <div className="memo-title">Total Revenue <span>•</span> 2022</div>
                <div className="memo-data">
                  TSh {services.commaSeparate(totalRevenue)}
                </div>
              </div>
            ) : null
          }
          <div className="memo-container orders">
            <div className="memo-title">Orders <span>•</span> Recent</div>
            <div className="memo-data">
              {services.commaSeparate(recentOrders.length)}
            </div>
          </div>
          {
            ([1].includes(authority)) ? (
              <div className="memo-container visitor">
                <div className="memo-title">Visitors <span>•</span> Today</div>
                <div className="memo-data">
                  0
                </div>
              </div>
            ) : null
          }
          {
            ([1].includes(authority)) ? (
              <div className="memo-container users">
                <div className="memo-title">Total Users</div>
                <div className="memo-data">
                  {services.commaSeparate(users.length)}
                </div>
              </div>
            ) : null
          }
          <div className="memo-container pakages">
            <div className="memo-title">Total Packages</div>
            <div className="memo-data">
              {services.commaSeparate(packages.length)}
            </div>
          </div>
        </section>
      </div>

      {/* 
      <section className='table-section'>
        {
          ([1].includes(authority)) ? (
            <div>
              <div className='bg-light p-3 my-4'>
                <div className="d-flex align-items-center my-2 mx-4">
                  <div className="hr"></div>
                  <small className="mx-3 background-light">Income/Revenue</small>
                  <div className="hr w-100"></div>
                </div>
                <canvas id="myChart" width="400" height="100"></canvas>
              </div>
            </div>
          ) : null
        }
      </section> */}
      <div className='d-flex flex-row-reverse justify-content-end align-items-start my-1'>
        {/* <section className='d-flex justify-content-center align-items-center'> */}
        <div class="alert alert-light" role="alert">
          For more options on Orders go to <b>orders</b> tab.
        </div>
        {/* </section> */}
      </div>
      <DataTable columns={columns} rows={recentOrders} />
    </>
  ) : (<Loader />)
}

export default Dashboard
