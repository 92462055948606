import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Services from '../../services.js';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { logRoles } from '@testing-library/react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FaFileUpload } from 'react-icons/fa';
import { $ } from 'react-jquery-plugin';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CreateUser = ({ pannel = '', onSuccess = ()=>{} }) => {
    let services = new Services()
    const navigate = useNavigate()
    // const history = useHistory();

    const [value, setValue] = useState(0);
    const [Pannel, setPannel] = useState('');

    const [isRegistering, setIsRegistering] = useState(false);

    // ///////////////////////////////////////////////////////// //

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [avatar, setAvatar] = useState(null);
    // ---------------------------------------------------------------- //
    const [validEmail, setValidEmail] = useState(false)

    // ///////////////////////////////////////////////////////// //

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 0) {
            navigate("/users/new/manual")
        }
        else if (newValue == 1) {
            navigate("/users/new/invite")
        }
        else if (newValue == 2) {
            navigate("/users/new/upload")
        }
    };

    const [roles, setRoles] = useState([
        "Admin",
        "Staff",
        "Account"
    ]);
    const [role, setRole] = useState(1);
    const [isUploaded, setIsUploaded] = useState(false);

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const fileUploadHandler = (event) => {
        if ($("#csvFile")[0].files.length == 1) {
            setIsUploaded(true)
        } else {
            setIsUploaded(false)
        }
    };

    const validateEmail = (value) => {
        if (value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/gi)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }
        setEmail(value)
    }

    const imageChangeHandler = (event) => {
        let fileReader = new FileReader()
        // fileReader.readAsDataURL
        document.getElementById("avatarImage").style.backgroundImage = ''
        console.log(URL.createObjectURL(event.target.files[0]))
    }

    useEffect(() => {
        if (pannel == 'manual') setValue(0);
        if (pannel == 'invite') setValue(1);
        if (pannel == 'upload') setValue(2);
        setPannel(pannel)
    })

    const submit = async () => {
        setIsRegistering(true)

        let formData = new FormData();

        let avatar = document.getElementById("avatarFile").files[0]

        // let fileReader = new FileReader()

        // let file = new File(['user'], '../../../assets/user.png',)

        // fileReader.readAsArrayBuffer(file)

        // let image = fileReader.result

        // fileReader.onload = async function () {
        //     var dataURL = fileReader.result;
        //     // console.log(fileReader)
        // };
        // image = fileReader;

        // formData.append("first_name", firstName)
        // formData.append("last_name", lastName)
        // formData.append("email", email)
        // formData.append("password", password)
        // formData.append("confirm_password", confirmPassword)
        // formData.append("phone", phone)
        // formData.append("username", username)
        // formData.append("roles_id", role)
        // formData.append("user_image", $("#avatarFile"))

        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "password": password,
            "c_password": confirmPassword,
            "phone": phone,
            "username": username,
            "roles_id": role,
            "user_image": avatar,
        }

        // console.log(avatar)
        try {
            let res = await services.registerUser(data);
            onSuccess(res, username)
            // console.log("hello")
        } catch (e) {
            console.log(e)
        }
        setIsRegistering(false)
    }

    const sendInvitation = async () => {
        let data = {
            "email": email,
            "role": role,
            "token": services.generateToken(64)
        }
        let response = await services.sendInvitation(data);
    }

    return (
        <div>
            <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
                <section className='d-flex justify-content-between w-100'>
                    <Link className='nav-li' to="/users" ><button type="button" className="btn btn-dark btn-sm px-3 mx-1 text-nowrap">Back</button></Link>
                    <button type="button" className="btn btn-dark btn-sm px-3 mx-1 text-nowrap">Reset</button>
                </section>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Manual" {...a11yProps(0)} />
                        <Tab label="Invite" {...a11yProps(1)} />
                        <Tab label="Upload" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <form action="" className='user-registration-form bg-light border-2 d-flex flex-column overflow-hidden'>
                        <div className='package-form-header'>
                            <p>Manual Registration</p>
                        </div>
                        <div className="d-flex flex-row align-items-start justify-content-center pb-3">
                            <div className="d-flex flex-column w-50 mx-1">
                                <TextField value={firstName} onChange={(e) => { setFirstName(e.target.value) }} type="text" className="m-3 w-70" label="First Name" variant="standard" />
                                <TextField value={lastName} onChange={(e) => { setLastName(e.target.value) }} type="text" className="m-3 w-70" label="Last Name" variant="standard" />
                                <TextField errorText={"invalid email"} value={email} onChange={(e) => { validateEmail(e.target.value) }} type="email" className="m-3 w-70" label="Email" variant="standard" />
                                <TextField value={username} onChange={(e) => { setUsername(e.target.value) }} type="text" className="m-3 w-70" label="Username" variant="standard" />
                                <TextField value={password} onChange={(e) => { setPassword(e.target.value) }} type="password" className="m-3 w-70" label="Password" variant="standard" />
                                <TextField value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} type="password" className="m-3 w-70" label="Confirm Password" variant="standard" />
                            </div>
                            <div className="d-flex flex-column w-50 mx-1">
                                <div onClick={(e) => { $("#avatarFile").click() }} id="avatarImage" className="user-registration-image m-3"></div>
                                <TextField onChange={(e) => { imageChangeHandler(e) }} hidden id='avatarFile' type="file" className="m-3 w-70" variant="standard" />
                                <Select
                                    // labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={role}
                                    label="Role"
                                    className="selection-option m-3 w-70"
                                    onChange={handleRoleChange}
                                    variant="standard"
                                    defaultValue={role}
                                >
                                    {
                                        roles.map((_role, index) => {
                                            return (
                                                <MenuItem value={index + 1}>{_role}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <TextField value={phone} onChange={(e) => { setPhone(e.target.value) }} type="text" className="m-3 w-70" label="Phone Number" variant="standard" />
                                <div className='d-flex justify-content-center'>
                                    <button type="button" className="btn btn-light btn-sm px-3 mx-1 text-nowrap">Reset</button>
                                    {(isRegistering == true) ? (
                                        <div className='px-3 text-nowrap mx-2'>
                                            <CircularProgress disableShrink style={{ color: "black" }} size={24} />
                                        </div>
                                    ) : (
                                        <button onClick={submit} type="button" className="btn btn-dark btn-sm px-3 mx-1 text-nowrap">Submit</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <form action="" className='user-registration-form bg-light border-2 d-flex flex-column overflow-hidden'>
                        <div className='package-form-header'>
                            <p>Send Invitation</p>
                        </div>
                        <div className="d-flex flex-row align-items-start justify-content-center pb-3">
                            <div className="d-flex flex-column w-50 mx-1">
                                <TextField errorText={"invalid email"} value={email} onChange={(e) => { validateEmail(e.target.value) }} id="outlined-basic" type="text" className="m-3 w-70" label="Email" variant="standard" />
                                <Select
                                    // labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={role}
                                    label="Role"
                                    className="selection-option m-3"
                                    onChange={handleRoleChange}
                                    variant="standard"
                                    defaultValue={role}
                                >
                                    {
                                        roles.map((_role, index) => {
                                            return (
                                                <MenuItem value={index + 1}>{_role}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <button onClick={(e) => { sendInvitation() }} type="button" className="btn btn-dark btn-sm px-3 mx-3 my-3 text-nowrap">Send Invitation</button>
                            </div>
                        </div>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <small>
                        <p>Upload <b>CSV</b> File (STEPS)</p>
                        <ol>
                            <li>Open Your Excell File</li>
                            <li>Go to File</li>
                            <li>Choose Save As</li>
                            <li>Choose Where You Want To Save To</li>
                            <li>From Save As Type Option, Choose <b>CSV</b></li>
                            <li>Click Upload Button Bellow And Choose The CSV File</li>
                        </ol>
                    </small>
                    <form action="" className='user-registration-form bg-light border-2 d-flex flex-column overflow-hidden'>
                        <div className='package-form-header'>
                            <p>Upload CSV File</p>
                        </div>
                        <div className="d-flex flex-row align-items-start justify-content-center pb-3">
                            <input type="file" className='d-none' id='csvFile' onChange={fileUploadHandler} />
                            {
                                (isUploaded) ? (
                                    <div className='d-flex flex-column align-items-center justify-content-center'>
                                        <CircularProgress
                                            variant="indeterminate"
                                            disableShrink
                                            sx={{
                                                color: '#1a90ff',
                                                animationDuration: '550ms',
                                            }}
                                        // size={40}
                                        // thickness={4}
                                        />
                                        <button type="button" className="btn btn-dark btn-sm px-3 mx-3 my-3 text-nowrap" onClick={(e) => { $("#csvFile").val([]); $("#csvFile").click() }}>
                                            <FaFileUpload /> Delete And Upload
                                        </button>
                                    </div>
                                ) : (
                                    <button type="button" className="btn btn-dark btn-sm px-3 mx-3 my-3 text-nowrap" onClick={(e) => { $("#csvFile").click() }}>
                                        <FaFileUpload /> Upload
                                    </button>
                                )
                            }
                        </div>
                    </form>
                </TabPanel>
            </Box>
        </div >
    );
}

export default CreateUser;