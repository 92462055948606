import { useEffect, useState } from "react";
import Services from '../../services.js';
import Invoice from "../includes/invoiceModel.js";
import { $ } from 'react-jquery-plugin';

const Invoices = () => {
    let services = new Services()
    const [invoices, setInvoices] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const sync = async () => {
            let _invoices = await services.getInvoices();
            _invoices = _invoices.data;
            // console.log(_invoices)
            setInvoices(_invoices)

            setTimeout(() => {
                $("#invoicesTable").DataTable({
                    autoWidth: false,
                    destroy: true,
                    jQueryUI: true,
                    // dom: "rBftlip",
                    order: [[1, 'desc']],
                    pageLength: 10,
                })
            }, 160);
        }
        sync()
    }, []);

    const view = async (data) => {
        setIsOpen(true)
        setInvoiceData(data)
    }

    return (
        <>
            {/* <AlertMessage onApprove={deletePackage} message={alertMessage} isOpen={openAlertBox} close={handleCloseAlertBox} open={handleOpenAlertBox} /> */}
            {/* <OrderPlacerModel isOpen={openCreatePackageModal} close={handleCloseCreatePackageModal} open={handleOpenCreatePackageModal} /> */}
            <Invoice data={invoiceData} isopen={isOpen} onClose={() => { setIsOpen(false) }} />
            <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
                <section className='d-flex justify-content-end'>
                    <button disabled type="button" className="btn btn-dark btn-sm px-3 text-nowrap">Bulk Download</button>
                </section>
            </div>
            <table id="invoicesTable" className="stripe">
                <thead className='border-top'>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Invoice Number</th>
                        <th scope="col">Price (TSh)</th>
                        {/* <th scope="col">Package</th> */}
                        <th scope="col">Balance (TSh)</th>
                        <th scope="col">Ship To</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        invoices.map((invoice, index) => (
                            <tr key={index}>
                                <th scope="row">{new Date(invoice['created_at']).toDateString()}</th>
                                <td>{invoice['invoice_number']}</td>
                                <td>{invoice['total_price']}</td>
                                <td>{invoice['balance_due']}</td>
                                {/* <td>{order['order_type']}</td> */}
                                {/* <td>{invoice['price']}</td> */}
                                <td>{invoice === null ? "-----" : invoice['bill_to'] && JSON.parse(invoice['bill_to'])[0]}</td>
                                <td>{invoice['status']}</td>
                                <td><button onClick={(e) => { view(invoice) }} type="button" className="btn btn-dark  btn-sm px-3">View</button></td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </>
    );
}

export default Invoices;