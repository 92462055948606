import React from 'react'

const TraceLog = () => {
  return (
    <div>
      
    </div>
  )
}

export default TraceLog
