import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Services from '../../services.js';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { logRoles } from '@testing-library/react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FaFileUpload } from 'react-icons/fa';
import { $ } from 'react-jquery-plugin';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const Accessibility = ({ pannel = '' }) => {
    let services = new Services()
    const navigate = useNavigate()
    // const history = useHistory();

    const [accessRights, setAccessRight] = useState([])

    useEffect(() => {
        setTimeout(() => {
            $("#packageTable").DataTable({
                autoWidth: false,
                destroy: true,
                jQueryUI: true,
                // dom: "rBftlip",
                order: [[1, 'desc']],
                pageLength: 10,
            })
        }, 150);
    })


    return (
        <div>
            <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
                <section className='d-flex justify-content-between w-100'>
                    <Link className='nav-li' to="/users" ><button type="button" className="btn btn-dark btn-sm px-3 mx-1 text-nowrap">Back</button></Link>
                    <button type="button" className="btn btn-dark btn-sm px-3 mx-1 text-nowrap">New Role</button>
                </section>
            </div>
            <table id='packageTable' className='stripe' width="100%">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Role</th>
                        <th>Read</th>
                        <th>Write</th>
                        <th>Delete</th>
                        <th>Update</th>
                        <th width="20%">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        accessRights.map((access, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{`${access['username']}`}</td>
                                <td>{`${access['first_name']} ${access['last_name']}`}</td>
                                <td>{access['email']}</td>
                                <td>{access['phone']}</td>
                                <td>{access['roles_id']}</td>
                                <td width="20%">
                                    <button type="button" className="btn btn-dark  btn-sm px-3">Update</button>
                                    <button type="button" className="btn btn-danger  btn-sm px-3 mx-1">Delete</button>
                                </td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
        </div >
    );
}

export default Accessibility;