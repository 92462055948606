import React, { useEffect, useState } from 'react'
import Services from '../../services.js';
import { $ } from 'react-jquery-plugin';
import Loader from "../includes/Loader.js";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai'

const Users = () => {
  let services = new Services()
  const navigate = useNavigate()
  const location = useLocation();

  const [isLoaded, setLoaded] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function sync() {
      if (services.authStatus == false) {
        navigate('./login', { replace: true });
        return
      } else {
        if (users.length == 0) {
          let _users = await services.getUsers()
          _users = _users.data;
          setUsers(_users)
        }
      }
      setTimeout(() => {
        if (isLoaded == false) {
          $("#packageTable").DataTable({
            autoWidth: false,
            destroy: true,
            jQueryUI: true,
            // dom: "rBftlip",
            order: [[1, 'desc']],
            pageLength: 10,
          })
          setLoaded(true);
          console.log(users)
        }
      }, 150);
    }
    sync()
    $("#packageTable").dataTable().next();
  }, [users])

  return isLoaded ? (
    <div>
      <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
        <section className='d-flex justify-content-end'>
          <Link to="/users/new" ><button type="button" className="btn btn-dark btn-sm px-3 mx-1 text-nowrap">Register User</button></Link>
          <Link to="/users/new/invite" ><button type="button" className="btn btn-dark btn-sm px-3 mx-1 text-nowrap">Invite User</button></Link>
          <Link to="/users/accessibility" ><button type="button" className="btn btn-dark btn-sm px-3 mx-1 text-nowrap">Access Rights</button></Link>
        </section>
        <section className='memo-section'>
          <div className="memo-container pakages">
            <div className="memo-title">Total Users</div>
            <div className="memo-data">
              {users.length}
            </div>
          </div>
        </section>
      </div>
      {/* <PackageTable tableTitle = "Packages"/> */}
      <table id='packageTable' className='stripe' width="100%">
        <thead>
          <tr>
            <th>#</th>
            <th>Username</th>
            <th>Full Name</th>
            <th>Email Address</th>
            <th>Last Logein</th>
            <th>Role</th>
            <th width="20%">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            users.map((user, index) => {
              let role = "";
              switch(user['roles_id']){
                case "1":
                  role = "Admin";
                  break;
                case "2":
                  role = "Staff";
                  break;
                case "3":
                  role = "HR";
                  break;
                case "4":
                  role = "Account";
                  break;
              }
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{`${user['username']}`}</td>
                  <td>{`${user['first_name']} ${user['last_name']}`}</td>
                  <td>{user['email']}</td>
                  <td>{user['last_logged']}</td>
                  <td>{role}</td>
                  <td width="20%">
                    <button type="button" className="btn btn-dark  btn-sm px-3">Profile</button>
                    <button type="button" className="btn btn-danger  btn-sm px-3 mx-1">Delete</button>
                  </td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  ) : (<Loader />)
}

export default Users
