import react, { useEffect, useState, forwardRef } from 'react';
import logo from '../../assets/logoblack.svg'
import Services from '../../services.js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { Link, useNavigate, useParams } from 'react-router-dom';

const Register = ({ }) => {
    let services = new Services()
    let { token } = useParams();
    let navigate = useNavigate()
    // let isValid = false;
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [password, setPassword] = useState('');
    const [load, setLoad] = useState(false);
    const [errors, setError] = useState([]);

    const checkUser = async () => {
        setLoad(true)
        // isValid = true;
        setError([])
        try {
            const isValid = await services.tokenVerify(token)
            let dt = isValid.data;
            let data = {
                email: email,
                first_name: firstName,
                last_name: lastName,
                phone: phone,
                username: `${firstName} ${lastName}`,
                password: password,
                c_password: passwordConfirmation,
                roles_id: dt['role'],
            }
            setLoad(false)
            if (isValid) {
                if((password != passwordConfirmation) || (password == "" || passwordConfirmation == "")){
                    setOpen(false);
                    setOpenError(true);
                    setMessageAlert(`Validation error, please verify your credentials`)
                }else{
                    setOpen(true);
                    setOpenError(false);
                    setMessageAlert(`Validation test passed`)
                    await new Promise(w => setTimeout(w, 3000))
                    setOpen(true);
                    const response = await services.registerUser(data)
                    if(response.success){
                        setMessageAlert(`Successfully registered`)
                        await new Promise(w => setTimeout(w, 3000))
                        setMessageAlert(`Redirecting to login...`)
                        await new Promise(w => setTimeout(w, 3000))
                        setOpenError(false);
                        setOpen(false);
                        localStorage.setItem("email", email);
                        navigate("/login")
                    }
                }
            } else {
                setOpen(false);
                setOpenError(true);
            }
            // if (isValid) window.location.reload(false);
        } catch (e) {
            setLoad(false)
            let message = e.response.data.message;
            if (message == "Unauthorised.") {
                setError([`${message} Please Login`])
                setOpenError(true);
                setOpen(false);
            }
            // setError()
        }
        // console.log(isValid)


    }

    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        setOpen(false);
        setOpenError(false);
    };

    useEffect(() => {
        const sync = async () => {
            const isValid = await services.tokenVerify(token)
            setLoad(false)
            let data = isValid.data
            if (isValid) {
                setOpen(true);
                setOpenError(false);
                setMessageAlert(`Successfully Verified ${data['email']}`)
                setEmail(data['email'])
            } else {
                setOpen(false);
                setOpenError(true);
            }
        }
        sync()
    }, []);

    const passwordConfirmationHandler = async (value) => {
        setPasswordConfirmation(value)
    }


    return (
        <div className='auth-wrapper'>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {messageAlert}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={openError}
                autoHideDuration={10000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {messageAlert}
                </Alert>
            </Snackbar>
            <div className="auth-box">
                <div className="logo">

                    <img src={logo} alt="" width="65px" height="65px" className='my-2' />
                    <p className='text-center fw-bold'>STAFF PASSWORD RESET</p>
                    {/* {errors.map(error => {
                        return (<p key={error} className='errorList'>{error}</p>)
                    })} */}
                </div>
                <div className="inputs">
                    <TextField disabled value={email} id="standard-basic" className='my-1' label="Email Address" variant="standard" />
                    <TextField value={firstName} onChange={(e) => { setFirstName(e.target.value) }} id="standard-basic" className='my-1' label="First Name" variant="standard" />
                    <TextField value={lastName} onChange={(e) => { setLastName(e.target.value) }} id="standard-basic" className='my-1' label="Last Name" variant="standard" />
                    <TextField value={phone} onChange={(e) => { setPhone(e.target.value) }} id="standard-basic" className='my-1' label="Phone Number" variant="standard" />
                    <TextField type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} id="standard-basic" className='my-1' label="Password" variant="standard" />
                    <TextField type="password" value={passwordConfirmation} onChange={(e) => { passwordConfirmationHandler(e.target.value) }} id="standard-basic" className='my-1' label="Comfirm Password" variant="standard" />
                    {/* <input value={email} type="text" placeholder='Email' onInput={e => setEmail(e.target.value)} /> */}
                    {/* <input value={password} type="password" placeholder='Password' onInput={e => setPassword(e.target.value)} /> */}
                    <button type="button" className="btn btn-dark px-3 my-3" onClick={checkUser}>
                        {
                            (load) ? (
                                <div className='d-flex justify-content-center align-items-center'>
                                    <CircularProgress disableShrink style={{ color: "white" }} size={24} />
                                </div>
                            ) : "Submit"
                        }
                    </button>
                </div>
            </div>

            <p className='copy-right my-5'>All rights reserved &copy; | 2022 Haddypro</p>

        </div>
    )
}

export default Register;