import React from 'react';
import { Login } from './pages/Login';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';
import { NewPassword } from './pages/NewPasswordReset';
import { ResetPassword } from './pages/ResetPassword';
import Register from './pages/unauthReg';

const UnauthView = () => {
    return (
        <Router>
            <div className="main-section">
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/reset-password/:token/:email' element={<NewPassword />} />
                    <Route path='/password-recovery' element={<ResetPassword />} />
                    <Route path='/register/:token' element={<Register />} />

                    <Route path='/dashboard' element={<Login />} />
                    <Route path='/inquiry' element={<Login />} />
                    <Route path='/services' element={<Login />} />
                    <Route path='/equipments' element={<Login />} />
                    <Route path='/orders' element={<Login />} />
                    <Route path='/packages' element={<Login />} />
                    <Route path='/profile' element={<Login />} />
                    <Route path='/reports' element={<Login />} />
                    <Route path='/stock' element={<Login />} />
                    <Route path='/tracelog' element={<Login />} />
                    <Route path='/users' element={<Login />} />
                    <Route path='/users/new' element={<Login />} />
                    <Route path='/users/new/manual' element={<Login/>} />
                    <Route path='/users/new/invite' element={<Login/>} />
                    <Route path='/users/new/upload' element={<Login/>} />
                    <Route path='/web' element={<Login />} />
                </Routes>
            </div>
        </Router>
    );
}

export default UnauthView;