import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Services from '../../services.js';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { IoIosAddCircle } from 'react-icons/io'
import OrderModel from './orderModel.js';
import { AsyncCalendar } from './modals.js';
import { CircularProgress } from '@mui/material';

const OrderPlacerModel = ({ isOpen = false, open, close, submitHandler = () => { } }) => {

    let services = new Services()
    const navigate = useNavigate()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    const [name, setName] = useState('')
    const [fname, setFName] = useState('')
    const [sname, setSName] = useState('')
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalPriceDiscount, setTotalPriceDiscount] = useState(0)
    const [token, setToken] = useState('')
    const [phone, setPhone] = useState('')
    const [discount, setDiscount] = useState(0)
    const [orderItemsList, setOrderItemsList] = useState([])
    const [service, setService] = useState([])
    const [isPlacing, isetIsPlacing] = useState(false)

    useEffect(() => {
        async function sync() {
            if (token == '') setToken(services.generateToken())
        }
        sync();
    }, [])

    const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);
    const handleCloseCreatePackageModal = () => setOpenCreatePackageModal(false);
    const handleOpenCreatePackageModal = async () => setOpenCreatePackageModal(true);

    let items = [...orderItemsList]
    const addItem = (e) => {
        // items.push(Math.round());
        // setOrderItemsList(items);
        handleOpenCreatePackageModal();
    }

    const getService = async (data) => {
        let _ = [...orderItemsList];
        let isService = data['services'] != '';

        console.log(orderItemsList)


        if (isService) {
            let response = await services.getSpecService(data['services'])
            response = response.data
            console.log(new Date(data['date']['start']))
            _.push({
                order_type: "service",
                service: response['service_type'],
                discount: response['discount'],
                price: services.commaSeparate(response['price_tzs']),
                balance_due: services.commaSeparate(response['price_tzs']),
                total_price: services.commaSeparate(response['price_tzs']),
                service_id: response['id'],
                metric: response['metric'],
                charges: response['charges'],
                start_time: new Date(data['date']['start']),
                end_time: new Date(data['date']['end']),
                order_description: "",
                qty: 1,

            })
        } else {
            let response = await services.getSpecPackage(data['packages'])
            response = response.data
            // console.log(response)
            _.push({
                order_type: "package",
                service: response['package_name'],
                discount: response['discount'],
                package_id: response['id'],
                metric: response['metric'],
                charges: response['charges'],
                price: services.removeComma(response['price_tzs']),
                balance_due: services.removeComma(response['price_tzs']),
                total_price: services.removeComma(response['price_tzs']),
                start_time: new Date(data['date']['start']),
                end_time: new Date(data['date']['end']),
                order_description: "",
                qty: 1,
            })
        }

        let _totalPrice = 0
        let _totalPriceDiscount = 0

        _.forEach(item => {
            let __totalPrice = +services.removeComma(item['price'])
            __totalPrice = (+item['discount'] / 100) * __totalPrice;
            __totalPrice = +services.removeComma(item['price']) - +__totalPrice
            _totalPrice += __totalPrice
            // console.log(+item['discount'])
        })
        // console.log(_totalPrice)
        setTotalPrice(services.commaSeparate(`${_totalPrice}`))
        setTotalPriceDiscount(discount / 100 * totalPrice);
        setOrderItemsList(_)
        handleCloseCreatePackageModal()
        setTotalPriceDiscount(_totalPrice);
        // console.log(data)
    }

    const placeOrder = async (e) => {
        isetIsPlacing(true);
        let data = {
            "client_name": `${fname} ${sname}`,
            "order_token": token,
            "client_phone": phone,
            "totalPrice": totalPrice,
            "totalPriceDiscount": totalPriceDiscount,
            "discount": discount,
            "products": orderItemsList,
            // "order_type": "mixed",
            client_email: "@",
            order_processed_by: localStorage.getItem("email"),
        }
        try{
            let response = await services.placeOrder(data);
            console.log(response)
        }catch(e){}
        submitHandler()
        isetIsPlacing(false);
    }

    const clearOrders = () => {
        setOrderItemsList([]);
        setName("")
        setFName("")
        setSName("")
        setPhone("")
        setTotalPrice("")
        setTotalPriceDiscount("")
        setDiscount(0)
        setService("")
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpen}
                onClose={(e) => { close(); setToken(''); }}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                keepMounted
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpen}>
                    <Box sx={style}>
                        <form className='popup-form'>
                            <div className='package-form-header'>
                                <p>Place Order</p>
                            </div>
                            <div className='p-3 d-flex flex-row justify-content-between'>
                                <div className='orders-list d-flex flex-column bg-light mx-2 px-2 py-3 position-relative'>
                                    <div className="d-flex position-fixed top-0 end-0 m-2">
                                        <button type='button' className='btn btn-dark btn-sm px-2 text-nowrap mx-1 my-2 d-flex justify-content-between align-items-center' onClick={(e) => addItem(e)} aria-label="delete">
                                            <IoIosAddCircle className='mx-1' size={22} />
                                            Add Service
                                        </button>
                                    </div>
                                    <table className='text-center'>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Service</th>
                                                <th>Date</th>
                                                <th>Price</th>
                                                <th>Discount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                orderItemsList.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item['service']}</td>
                                                            <td></td>
                                                            <td>{item['price']}/=</td>
                                                            <td>{item['discount']}%</td>
                                                        </tr>

                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    {/* {
                                        orderItemsList.map((item, index) => {
                                            return (
                                                <div className="order-item my-2 d-flex py-2">
                                                    <div className="order-item-property">
                                                        <p className="property-label">Service</p>
                                                        <p className="property-value">{item['service']}</p>
                                                    </div>
                                                    <div className="order-item-property">
                                                        <p className="property-label">Date</p>
                                                        <p className="property-value">{item['date']}</p>
                                                    </div>
                                                    <div className="order-item-property">
                                                        <p className="property-label">Price</p>
                                                        <p className="property-value">{item['price']}</p>
                                                    </div>
                                                    <div className="order-item-property">
                                                        <p className="property-label">Discount</p>
                                                        <p className="property-value">{item['discount']}</p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    } */}
                                </div>
                                <div className='orders-fields-list d-flex flex-column w-50 mx-2'>
                                    <TextField value={token} onChange={(e) => { setToken(e.target.value) }} id="standard-basic" className='my-2' label="Token" variant="standard" />
                                    <TextField value={fname} onChange={(e) => { setFName(e.target.value) }} id="standard-basic" className='my-2' label="First Name" variant="standard" />
                                    <TextField value={sname} onChange={(e) => { setSName(e.target.value) }} id="standard-basic" className='my-2' label="Second Name" variant="standard" />
                                    <TextField value={phone} onChange={(e) => { setPhone(e.target.value) }} id="standard-basic" className='my-2' label="Phone Number" variant="standard" />
                                    <TextField disabled value={totalPrice} id="standard-basic" className='my-2' label="Price" variant="standard" />
                                    <TextField type='number' InputProps={{ inputProps: { min: 0, max: 100 } }} value={discount}
                                        onChange={(e) => {
                                            if (+(e.target.value) >= 0 && +(e.target.value) <= 100) {
                                                // if (e.target.value == "") {
                                                // setDiscount(0)
                                                // } else {
                                                // }
                                                setDiscount(+(e.target.value))
                                                let _discount = +(e.target.value)
                                                let _totalPrice = +(services.removeComma(totalPrice))
                                                _totalPrice = (_totalPrice - ((_discount / 100) * _totalPrice))
                                                setTotalPriceDiscount(_totalPrice);
                                            }
                                        }}
                                        id="standard-basic"
                                        className='my-2'
                                        label="Discount %"
                                        variant="standard" />
                                    <TextField disabled value={totalPriceDiscount} id="standard-basic" className='my-2' label={`Price ${discount}% off`} variant="standard" />
                                </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-end">
                                {/* <Button variant="text">Close</Button> */}
                                <button type="button" onClick={(e) => { close(); setToken(''); }} className="btn btn-light btn-sm px-3 text-nowrap mx-3">Cancel</button>
                                <button type="button" onClick={(e) => { clearOrders(e) }} className="btn btn-danger btn-sm px-3 text-nowrap mx-3">Clear</button>
                                {
                                    (isPlacing == true) ? (
                                        <div className='px-3 text-nowrap mx-2'>
                                            <CircularProgress disableShrink style={{ color: "black" }} size={24} />
                                        </div>
                                    ) : (
                                        <button type="button" onClick={placeOrder} className="btn btn-dark btn-sm px-3 text-nowrap mx-3">Create</button>
                                    )
                                }
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
            <OrderModel submitHandler={getService} isOpen={openCreatePackageModal} close={handleCloseCreatePackageModal} open={handleOpenCreatePackageModal} />
        </div>
    );
}

export default OrderPlacerModel;