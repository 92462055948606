import react, { useEffect, useState } from 'react';
import Loader from '../includes/Loader';
import Services from '../../services.js';
import { $ } from 'react-jquery-plugin';
import { useNavigate, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { logRoles } from '@testing-library/react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FaFileUpload } from 'react-icons/fa';
import PropTypes from 'prop-types';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Web = () => {
    let services = new Services()
    const navigate = useNavigate()
    const location = useLocation();

    const [isLoaded, setLoaded] = useState(false);
    const [webContents, setWebContents] = useState([]);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        async function sync() {
            if (services.authStatus == false) {
                navigate('./login', { replace: true });
                return
            } else {
                if (webContents.length == 0) {
                    let _webContents = await services.getWebContent()
                    _webContents = _webContents.data;
                    setWebContents(_webContents)

                }
            }
            setTimeout(() => {
                if (isLoaded == false) {
                    $("#packageTable").DataTable({
                        autoWidth: false,
                        destroy: true,
                        jQueryUI: true,
                        // dom: "rBftlip",
                        order: [[1, 'desc']],
                        pageLength: 10,
                    })
                    setLoaded(true);
                    console.log(webContents)
                }
            }, 1);
        }
        sync()
    }, [webContents])

    return isLoaded ? (
        <div>
            <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
                <section className='d-flex justify-content-end'>
                    <button type="button" className="btn btn-dark btn-sm px-3 text-nowrap">Save</button>
                </section>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Home Page" {...a11yProps(0)} />
                        <Tab label="About Page" {...a11yProps(1)} />
                        <Tab label="Contact Page" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <form action="" className='web-update-form bg-light border-2 d-flex flex-column p-3'>
                        <div className="slider d-flex">
                            <div className='bg-dark p-5'></div>
                            <p contentEditable>
                                Our vast menu of products includes Paging Microphones, Mixer, Amplifyers, Wall and Ceiling Speakers, Attenuators, and Controllers, all from industry leading brands that form an end-to-end solution
                            </p>
                        </div>
                    </form>
                </TabPanel>
            </Box>
        </div>
    ) : (<Loader />);
}

export default Web;