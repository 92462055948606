import { useState } from "react";
import { AiOutlineStock } from "react-icons/ai";
import { BsCalendarRangeFill, BsFillCalendarWeekFill, BsFillPersonFill, BsFillSpeakerFill } from "react-icons/bs";
import { CgComponents } from "react-icons/cg";
import { FaFileInvoice, FaGlobeAsia, FaUsers } from "react-icons/fa";
import { GoPackage } from "react-icons/go";
import { HiDocumentReport } from "react-icons/hi";
import { IoMdMail } from "react-icons/io";
import { MdDashboard, MdInventory2 } from "react-icons/md";
import { RiFootprintFill } from "react-icons/ri";
import { TbDoorEnter } from "react-icons/tb";

export const Nav = () => {
    return {
        dashboard : {
            name: "Dashboard",
            link: "/",
            icon: <MdDashboard className='react-icons' size="21px" />,
            authority: [1,2,3],
        },
        orders : {
            name: "Orders",
            link: "/orders",
            icon: <CgComponents className='react-icons' size="21px" />,
            authority: [1,2],
        },
        equipments : {
            name: "Equipments",
            link: "/equipments",
            icon: <BsFillSpeakerFill className='react-icons' size="21px" />,
            authority: [1],
        },
        services : {
            name: "Services",
            link: "/services",
            icon: <TbDoorEnter className='react-icons' size="21px" />,
            authority: [1],
        },
        packages : {
            name: "Packages",
            link: "/packages",
            icon: <GoPackage className='react-icons' size="21px" />,
            authority: [1],
        },
        // inventory : {
        //     name: "Inventory",
        //     link: "/inventory",
        //     icon: <MdInventory2 className='react-icons' size="21px" />,
        //     authority: [1],
        // },
        Schedule : {
            name: "Schedule",
            link: "/schedule",
            icon: <BsCalendarRangeFill className='react-icons' size="21px" />,
            authority: [1,2],
        },
        inquiry : {
            name: "Inquiry",
            link: "/inquiry",
            icon: <IoMdMail className='react-icons' size="21px" />,
            authority: [1,2],
        },
        report : {
            name: "Reports",
            link: "/reports",
            icon: <HiDocumentReport className='react-icons' size="21px" />,
            authority: [1],
        },
        invoices : {
            name: "Invoices",
            link: "/invoices",
            icon: <FaFileInvoice className='react-icons' size="21px" />,
            authority: [1],
        },
        // web : {
        //     name: "Web",
        //     link: "/web",
        //     icon: <FaGlobeAsia className='react-icons' size="21px" />,
        //     authority: [1],
        // },
        // analytics : {
        //     name: "Analytics",
        //     link: "/analytics",
        //     icon: <AiOutlineStock className='react-icons' size="21px" />,
        //     authority: [1],
        // },
        users : {
            name: "Users",
            link: "/users",
            icon: <FaUsers className='react-icons' size="21px" />,
            authority: [1],
        },
        // tracelog : {
        //     name: "Tracelog",
        //     link: "/tracelog",
        //     icon: <RiFootprintFill className='react-icons' size="21px" />,
            // authority: [1],
        // },
        profile : {
            name: "Profile",
            link: "/profile",
            icon: <BsFillPersonFill className='react-icons' size="21px" />,
            authority: [1,2,3,4],
        },
    }
}
 
export default Nav;