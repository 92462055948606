import React, { useEffect, useState } from 'react'
import Services from '../../services.js';
import { $ } from 'react-jquery-plugin';
import Loader from "../includes/Loader.js";
import { useNavigate, useLocation } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai'
import OrderModel from '../includes/orderModel.js';
import { InquiryModal, AlertMessage, AsyncCalendar } from '../includes/modals';


const Reports = () => {
  let services = new Services()
  const navigate = useNavigate()
  const location = useLocation();

  const [isLoaded, setLoaded] = useState(false);
  const [orders, setOrders] = useState([]);

  const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);
  const handleCloseCreatePackageModal = () => setOpenCreatePackageModal(false);
  const handleOpenCreatePackageModal = async () => setOpenCreatePackageModal(true);

  useEffect(() => {
    async function sync() {
      if (services.authStatus == false) {
        navigate('./login', { replace: true });
        return
      } else {
        if (orders.length == 0) {
          try {
            let _orders = await services.getOrders()
            _orders = _orders.data;
            setOrders(_orders)
          } catch (error) { }
        }
      }
      setTimeout(() => {
        if (isLoaded == false) {
          $("#packageTable").DataTable({
            autoWidth: false,
            destroy: true,
            jQueryUI: true,
            // dom: "rBftlip",
            order: [[1, 'desc']],
            pageLength: 10,
          })
          setLoaded(true);
          // console.log(orders)
        }
      }, 1);
    }
    sync()
  }, [orders])

  const exportExcel = async () => {
    let labels = ["#", "Time", "Name", "Email/Phone", "Status", "Price (TSh)"]
    let rows = []
    orders && orders.map((order, index) => {
      rows.push([
        index + 1,
        new Date(order['created_at']).toDateString(),
        order['client_name'],
        order['client_phone'],
        order['status'],
        services.commaSeparate(order['price']),
      ])
    })
    services.excelExport("Orders Report", labels, rows)
  }

  return isLoaded ? (
    <div>
      {/* <AsyncCalendar/> */}
      <OrderModel isOpen={openCreatePackageModal} close={handleCloseCreatePackageModal} open={handleOpenCreatePackageModal} />
      <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
        <section className='d-flex justify-content-end'>
          <button onClick={exportExcel} type="button" className="btn btn-dark btn-sm px-3 text-nowrap">Export xlsx</button>
        </section>
      </div>
      {/* <PackageTable tableTitle = "Packages"/> */}
      <table id='packageTable' className='stripe' width="100%">
        <thead>
          <tr>
            <th>#</th>
            <th>Time</th>
            <th>Name</th>
            <th>Email/Phone</th>
            <th>Status</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {
            orders && orders.map((order, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{new Date(order['created_at']).toDateString()}</td>
                <td>{order['client_name']}</td>
                <td>{order['client_phone']}</td>
                <td>{order['status']}</td>
                <td>TSh. {services.commaSeparate(order['price'])}</td>
              </tr>
              )
            )
          }
        </tbody>
      </table>
    </div>
  ) : (<Loader />)
}

export default Reports
