import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Services from '../../services.js';
import { useNavigate } from 'react-router-dom';
import { IoMdCall, IoIosSend } from 'react-icons/io'
import IconButton from '@mui/material/IconButton';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BiSync } from 'react-icons/bi';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import { StaticTimePicker } from '@mui/x-date-pickers';
import { Autocomplete, Chip, CircularProgress, FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import logo from '../../assets/prologo.png';


////////////////////// Constants ///////////////////////////
const style = {
    // width: '900px',
    // maxHeight: '200px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

////////////////////////////////////////////////////////////

const Invoice = ({ data, isopen = false, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(isopen);

    const [totalPrice, setTotalPrice] = useState("-");
    const [subTotal, setSubTotal] = useState("-");
    const [totalTax, setTotalTax] = useState("-");
    const [shipingHandling, setShipingHandling] = useState("-");
    const [balanceDue, setBalanceDue] = useState("-");

    let services = new Services()

    const close = () => {
        setIsOpen(false)
        onClose()
    }

    const onDownload = async () => {
        // setIsOpen(false)
    }


    useEffect(() => {
        let _subTotal = 0;
        let _total = 0;
        let _totalTax = 0;
        let _shipingHandling = 0;
        let _balanceDue = 0;

        try {
            data['products'].map((item, index) => {
                _subTotal += +item['total_price']
            })
        } catch (e) { }


        _total = +_totalTax + +_shipingHandling + +_balanceDue + +_subTotal
        _balanceDue = _total;
        setSubTotal(_subTotal);
        setTotalTax(_totalTax)
        setShipingHandling(_shipingHandling)
        setBalanceDue(_balanceDue)
        setTotalPrice(_total)
    }, [data])

    return isopen && (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isopen}
            onClose={close}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            keepMounted
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isopen}>
                <Box sx={style}>
                    <form className='popup-form invoice-model'>
                        <div className='package-form-header'>
                            <p>Invoice Preview</p>
                        </div>
                        <div className='scrollable-section d-flex flex-column'>
                            <section className='invoice-display d-flex flex-column justify-content-start'>
                                {
                                    data != null ? (
                                        <div className="invoice">
                                            <div className='invoice-header'>
                                                <div className="company-details">
                                                    <img className='my-2' src={logo} alt="" srcset="" width="150px" />
                                                    <p>{data['office_details']['company_name']}</p>
                                                    <p className='mb-2 max-50 w-75'>{data['office_details']['address']}</p>
                                                    <p className='d-flex border-bottom'><p className='wp'>Email: </p><span className='fw-bolder mx-4'>{data['office_details']['email']}</span></p>
                                                    <p className='d-flex border-bottom'><p className='wp'>Website: </p><span className='fw-bolder mx-4'>{data['office_details']['website']}</span></p>
                                                    <p className='d-flex border-bottom'><p className='wp'>Tel: </p><span className='fw-bolder mx-4'> {data['office_details']['phone1']} or {data['office_details']['phone1']}</span></p>

                                                </div>
                                                <div className="invoice-details">
                                                    <h3 className='my-2'>INVOICE</h3>
                                                    <p className='d-flex text-nowrap border-bottom'><p className='w-75'>Invoice No:</p> <span>{data === null ? "-----" : data['invoice_number']}</span></p>
                                                    <p className='d-flex text-nowrap border-bottom'><p className='w-75'>Invoice Date:</p> <span>{data === null ? "-----" : "2022-08-15"}</span></p>
                                                    <p className='d-flex text-nowrap border-bottom'><p className='w-75'>Due Date:</p> <span>{data === null ? "-----" : "2022-08-16"}</span></p>
                                                    <p className='d-flex text-nowrap border-bottom'><p className='w-75'>TIN:</p> <span>{data === null ? "-----" : data['tin']}</span></p>
                                                </div>
                                            </div>
                                            <div className="invoice-body">
                                                <div className="bill">

                                                    <div className="bill-to-header d-flex justify-content-between">
                                                        <div className="w-25"><p>Bill To:</p></div>
                                                        <div className="w-25 mx-3"><p>Ship To:</p></div>
                                                    </div>
                                                    <div className="bill-to-info d-flex justify-content-between">
                                                        <div className="bill-to d-flex justify-content-between flex-column">
                                                            <p className="">Name : <span>{data === null ? "-----" : data['bill_to'] && JSON.parse(data['bill_to'])[0]}</span></p>
                                                            <p className="">Phone : <span>{data['bill_to'] && JSON.parse(data['bill_to'])[1]}</span></p>
                                                        </div>
                                                        <div className="ship-to d-flex justify-content-between flex-column">

                                                            <p>Name : <span>{data === null ? "-----" : JSON.parse(data['bill_to'])['client_name']}</span></p>
                                                            <p>Phone : <span>{data === null ? "-----" : JSON.parse(data['bill_to'])['client_phone']}</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="invoice-discription my-4">
                                                        <table className='table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Product</th>
                                                                    <th>Qty</th>
                                                                    <th>Unit price(Tsh)</th>
                                                                    <th>Discount rate(%)</th>
                                                                    <th>Total(Tsh)</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data['products'].map((item, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{item['name']}</td>
                                                                                <td>{item['qty']}</td>
                                                                                <td>{services.commaSeparate(item['price'])}</td>
                                                                                <td>{item['discount']}</td>
                                                                                <td>{services.commaSeparate(item['total_price'])}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                                <tr className='bg-light fw-bolder'>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>SUBTOTAL</td>
                                                                    <td>{services.commaSeparate(subTotal)}</td>
                                                                </tr>
                                                                <tr className='bg-light fw-bolder'>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>TAX RATE</td>
                                                                    <td>{services.commaSeparate(totalTax)}</td>
                                                                </tr>
                                                                <tr className='bg-light fw-bolder'>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>TOTAL TAX</td>
                                                                    <td>{services.commaSeparate(totalTax)}</td>
                                                                </tr>
                                                                <tr className='bg-light fw-bolder'>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>SHIPPING/HANDLING</td>
                                                                    <td>{services.commaSeparate(totalTax)}</td>
                                                                </tr>
                                                                <tr className='bg-light fw-bolder'>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>TOTAL</td>
                                                                    <td>{services.commaSeparate(totalPrice)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table className='table'>
                                                            <thead>
                                                                <tr className='text-end'>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th className='text-end'>BALANCE</th>
                                                                    <th className='mx-0 text-end'>TSh {services.commaSeparate(balanceDue)}</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                        <p className='text-center fs-10'>All payment should be made in the Company name of HADDYPRO PA AND L CO LTD</p>
                                                        <p className='text-center fs-10'>SYSAUTOGEN</p>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    ) : null
                                }
                            </section>
                        </div>
                        <div className="w-100 d-flex align-items-center justify-content-end">
                            {/* <Button variant="text">Close</Button> */}
                            <button type="button" onClick={close} className="btn btn-light btn-sm px-3 text-nowrap mx-3">Close</button>
                            {/* {
                                (loading == true) ? (
                                    <div className='px-3 text-nowrap mx-2'>
                                        <CircularProgress disableShrink style={{ color: "black" }} size={24} />
                                    </div>
                                ) : (
                                    <button type="button" className="btn btn-dark btn-sm px-3 text-nowrap mx-2" onClick={async () => { setLoading(true); await onDownload(); setLoading(false) }}>Download</button>
                                )
                            } */}
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default Invoice