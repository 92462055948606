import React, { useEffect, useState } from 'react'
import Services from '../../services.js';
import { $ } from 'react-jquery-plugin';
import Loader from "../includes/Loader.js";
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertMessage } from '../includes/modals.js';
import { Alert, MenuItem, Select, Snackbar } from '@mui/material';

const ServicesPage = () => {

    let services = new Services()
    const navigate = useNavigate()
    const location = useLocation();

    const [name, nameState] = useState("");
    const [code, codeState] = useState("");
    const [charges, chargesState] = useState("hour");
    const [price, priceState] = useState("");
    const [maxPlacement, maxPlacementState] = useState("");
    const [discount, setDiscount] = useState('');
    const [isPublic, setIsPublic] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [forUpdate, setForUpdate] = useState(false);
    const [updateId, setUpdateId] = useState(null);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [deleteSuccess, setDeleteSuccess] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoriesObject, setCategoriesObject] = useState([]);
    const [category, setCategory] = useState('');
    const [categoryId, setCategoryId] = useState('');

    const [chargesList, setChargesList] = useState([]);
    const [chargesId, setChargesId] = useState(null);

    const [alertMessage, setAlertMessage] = useState(null);
    const [openAlertBox, setOpenAlertBox] = useState(false);
    const handleCloseAlertBox = () => setOpenAlertBox(false);
    const handleOpenAlertBox = async (e, message, id) => {
        setAlertMessage(message)
        setOpenAlertBox(true);
        setIdToDelete(id)
    }
    const [isLoaded, setLoaded] = useState(false);
    const [servicesList, setServices] = useState([]);
    const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);
    const handleCloseCreatePackageModal = () => {
        nameState('')
        codeState('')
        chargesState('')
        priceState('')
        maxPlacementState('')
        setIsPublic(true)
        setOpenCreatePackageModal(false);
        setUpdateId(null);
        setDiscount(0);
        setCategoryId('')
        setForUpdate(false)
        setCategory('')
        setChargesId(null)
    }
    const handleOpenCreatePackageModal = async () => {
        setOpenCreatePackageModal(true);
        setIsUpdating(false)
        setUpdateId(null);
    }
    const categoryTypeHandler = async (e, id) => {
        let value = e.target.value;
        setCategory(value)
        setCategoryId(value)
    }
    const viewUpdateModel = (data, id) => {
        let _public = (data['public'] == 0) ? false : true;
        setForUpdate(true)
        handleOpenCreatePackageModal()
        nameState(data['service_type']);
        codeState(data['service_code']);
        chargesState(data['charges']);
        priceState(services.commaSeparate(data['price_tzs']));
        maxPlacementState(data['qty']);
        setIsPublic(_public);
        setUpdateId(data['id']);
        setDiscount(data['discount']);
        setCategory(data['service_categories_id']);
        setCategoryId(data['service_categories_id'])
    }
    const saveService = async (e) => {
        let response = {};
        setIsUpdating(true)
        let isValid = false;
        if ((name != null) && (code != null) && (charges != null) && (price != null) && (maxPlacement != null)) {
            isValid = true;
        }
        let _price = ""
        for (let i = 0; i < price.length; i += 1) {
            if (price[i] != ",") _price += price[i]
        }

        if (isValid) {
            let data = {
                "service_type": name,
                "service_code": code,
                "service_categories_id": categoryId,
                // "charges": charges,
                charges_id: charges,
                "price_tzs": _price,
                "qty": +maxPlacement,
                "public": (isPublic == 1) ? true : false,
                "discount": discount,
                "description_of_service": name,
            }
            try {
                if (forUpdate) {
                    response = await services.updateService(data, updateId)
                } else {
                    data['service_category_id'] = categoryId
                    response = await services.registerService(data)
                }
                let _servicesList = await services.getServices()
                _servicesList = _servicesList.data;
                setServices(_servicesList)
                $("#packageTable").dataTable().next();
            } catch (error) {
                console.log(error)
            } finally {
                setIsUpdating(false)

            }
            // console.log(data)
        }
        setIsUpdating(false)
        handleCloseCreatePackageModal()
    }

    const deleteService = async () => {
        try {
            await services.deleteService(idToDelete)
            let _servicesList = await services.getServices()
            _servicesList = _servicesList.data;
            setServices(_servicesList)
            handleCloseAlertBox()
        } catch (e) {

        }
        setIdToDelete(null)
        console.log("Deleting Serive...")
    }

    useEffect(() => {
        async function sync() {
            if (services.authStatus == false) {
                navigate('./login', { replace: true });
                return
            } else {
                if (servicesList.length == 0) {
                    let _servicesList = await services.getServices()
                    _servicesList = _servicesList.data;
                    setServices(_servicesList)
                    let _charges = await services.getCharges()
                    _charges = _charges.data;
                    setChargesList(_charges)
                    // console.log(_servicesList)
                }
                if (categories.length == 0) {
                    let _servicesCategoryList = await services.getServiceCategories()
                    _servicesCategoryList = _servicesCategoryList.data;
                    let _srvcs = []
                    let obj = {}
                    _servicesCategoryList.forEach(srvc => {
                        _srvcs.push(srvc['service_category'])
                        obj[srvc['id']] = srvc['service_category']
                    })
                    setCategoriesObject(obj)
                    setCategories(_srvcs)
                }
            }
            setTimeout(() => {
                if (isLoaded == false) {
                    $("#packageTable").DataTable({
                        autoWidth: false,
                        destroy: true,
                        jQueryUI: true,
                        // dom: "rBftlip",
                        order: [[1, 'asc']],
                        pageLength: 10,
                    })
                    setLoaded(true);
                }
                // console.log(servicesList)
            }, 100);
        }
        sync()
    })

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    const chargesTypeHandler = async (e, id) => {
        let value = e.target.value;
        chargesState(value)
        console.log(value)
        setChargesId(value)
    }

    return isLoaded ? (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={snackOpen}
                autoHideDuration={10000}
                onClose={(e) => { setSnackOpen(false) }}>
                <Alert onClose={(e) => { setSnackOpen(false) }} severity="success" sx={{ width: '100%' }}>
                    Logedout, Redirecting...
                </Alert>
            </Snackbar>
            <AlertMessage onApprove={deleteService} message={alertMessage} isOpen={openAlertBox} close={handleCloseAlertBox} open={handleOpenAlertBox} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openCreatePackageModal}
                onClose={handleCloseCreatePackageModal}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                keepMounted
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openCreatePackageModal}>
                    <Box sx={style}>
                        <form className='popup-form'>
                            <div className='package-form-header'>
                                <p>Service Registration</p>
                            </div>
                            <div className='p-3 d-flex flex-column'>
                                <TextField value={name} onChange={(e) => { nameState(e.target.value) }} id="standard-basic" className='my-2' label="Service Name" variant="standard" />
                                <Select
                                    className='my-2 py-2'
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={category}
                                    onChange={categoryTypeHandler}
                                    label="Service Category"
                                    variant="standard"
                                >
                                    <MenuItem value="">
                                        <em>Choose Service Category</em>
                                    </MenuItem>
                                    {
                                        Object.keys(categoriesObject).map(type => (<MenuItem value={type}>{categoriesObject[type]}</MenuItem>))
                                    }
                                </Select>
                                <TextField value={code} onChange={(e) => { codeState(e.target.value) }} id="standard-basic" className='my-2' label="Service Code" variant="standard" />
                                <Select
                                    className='my-2 py-2'
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={charges}
                                    onChange={chargesTypeHandler}
                                    label="Charges Rate"
                                    variant="standard"
                                >
                                    <MenuItem value="">
                                        <em>Choose Service Category</em>
                                    </MenuItem>
                                    {
                                        chargesList.map(charge => (<MenuItem value={charge['id']}>{charge['charges']}</MenuItem>))
                                    }
                                </Select>
                                {/* <TextField value={charges} onChange={(e) => { chargesState(e.target.value) }} id="standard-basic" className='my-2' label="Charges Rate (/hour)" variant="standard" /> */}
                                <TextField pattern="^[0-9]*" value={price} onChange={(e) => {
                                    let val = e.target.value.replace(',', '')
                                    priceState(services.commaSeparate(val))
                                }} id="standard-basic" className='my-2' label="Service Price" variant="standard" />
                                <TextField
                                    type='number'
                                    value={discount} onChange={(e) => {
                                        let val = e.target.value
                                        if (+(val) >= 0 && +(val) <= 100) setDiscount(e.target.value)
                                    }}
                                    autoComplete={""}
                                    id="standard-basic"
                                    className='my-2'
                                    label="Discount %"
                                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                                    variant="standard" />
                                <TextField value={maxPlacement} onChange={(e) => { maxPlacementState(e.target.value) }} id="standard-basic" className='my-2' label="Maximum Placement" variant="standard" />
                                <FormControlLabel control={<Switch onChange={(e) => { setIsPublic(e.target.checked) }} checked={isPublic} />} label="Public" />
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-end">
                                {/* <Button variant="text">Close</Button> */}
                                <button type="button" onClick={handleCloseCreatePackageModal} className="btn btn-light btn-sm px-3 text-nowrap mx-3">Cancel</button>
                                <button type="button" onClick={saveService} className="btn btn-dark btn-sm px-3 text-nowrap mx-3">
                                    {(isUpdating) ? (
                                        <CircularProgress disableShrink style={{ color: "white" }} size={24} />
                                    ) : (
                                        "Save"
                                    )}
                                </button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
            <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
                <section className='d-flex justify-content-end'>
                    <button type="button" onClick={handleOpenCreatePackageModal} className="btn btn-dark btn-sm px-3 text-nowrap">Register new Service</button>
                </section>
                <section className='memo-section'>
                    <div className="memo-container pakages">
                        <div className="memo-title">Registered Services</div>
                        <div className="memo-data">
                            {servicesList.length}
                        </div>
                    </div>
                </section>
            </div>
            {/* <PackageTable tableTitle = "Packages"/> */}
            <table id='packageTable' className='stripe' width="100%">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Charges</th>
                        <th>Price (TSh)</th>
                        <th>Is public</th>
                        <th>Max Placement</th>
                        <th>Date Created</th>
                        <th width="20%">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        servicesList.map((service, index) => {
                            let _public = (service['public'] == 0) ? "false" : "true";
                            // console.log(_public)
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{`${service['service_type']}`}</td>
                                    <td>{`${service['service_code']}`}</td>
                                    <td>{`${service['charges']}`}</td>
                                    <td>{services.commaSeparate(service['price_tzs'])}</td>
                                    <td>{_public}</td>
                                    <td>{service['qty']}</td>
                                    <td>{new Date(service['created_at']).toDateString()}</td>
                                    <td width="20%">
                                        <button type="button" onClick={(e) => { viewUpdateModel(service, service['id']) }} className="btn btn-dark  btn-sm px-3 mx-2">Update</button>
                                        <button type="button" onClick={(e) => { handleOpenAlertBox(e, `Are you sure you want to delete ${service['service_type']} service ?`, service['id']) }} className="btn btn-danger  btn-sm px-3 mx-2">Delete</button>
                                    </td>
                                </tr>
                            )
                        }
                        )
                    }
                </tbody>
            </table>
        </div>
    ) : (<Loader />)
}

export default ServicesPage;