// import { Chart, registerables } from 'chart.js';
import { useEffect } from 'react';
import { $ } from 'react-jquery-plugin';

import { Chart, LineController, LineElement, PointElement, LinearScale, Title, BarController, CategoryScale, BarElement } from 'chart.js';

const Analytics = () => {

    Chart.register(LineController, LineElement, PointElement, LinearScale, BarController, BarElement, CategoryScale, Title);
    useEffect(() => {
        const sync = async () => {
            var chartExist = Chart.getChart("myChart"); // <canvas> id
            if (chartExist != undefined) chartExist.destroy();

            $('#myModal').modal('show');
            const ctx = document.getElementById('myChart').getContext('2d');
            const myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                    datasets: [
                        {
                            label: '# of Votes',
                            data: [12, 19, 3, 5, 2, 3],
                            borderWidth: 2.5,
                            borderColor: '#ffa500',
                            tension: 0.5,
                            fill: true,
                        },
                        {
                            label: '# of Votes',
                            data: [10, 5, 10,7, 5, 19],
                            borderWidth: 2.5,
                            borderColor: '#123123',
                            tension: 0.5,
                            fill: true,
                        }
                    ]
                },
                scales: {
                    x: {
                        type: 'linear'
                    },
                    y: {
                        type: 'linear'
                    }
                }
            })
        }
        sync()
    }, [])


    return (
        <div>
            <div className='bg-light p-3 my-4'>
                <div className="d-flex align-items-center my-2 mx-4">
                    <div className="hr"></div>
                    <small className="mx-3 background-light">Income/Revenue</small>
                    <div className="hr w-100"></div>
                </div>
                <canvas id="myChart" width="400" height="100"></canvas>
            </div>
        </div>
    );
}

export default Analytics;