import React, { useEffect, useState } from 'react'
import Services from '../../services.js';
import { $ } from 'react-jquery-plugin';
import Loader from "../includes/Loader.js";
import { useNavigate, useLocation } from 'react-router-dom';
import { MdAutoFixNormal } from 'react-icons/md'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { handleBreakpoints } from '@mui/system';
import { AlertMessage } from '../includes/modals.js';
import { CircularProgress } from '@mui/material';

const Equipments = () => {
  let services = new Services()
  const navigate = useNavigate()
  const location = useLocation();

  const [name, nameState] = useState("");
  const [type, typeState] = useState("");
  const [qty, qtyState] = useState("");
  const [eq, eqState] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatingId, setUpdatingId] = useState(null);
  const [isSavingEquipment, setIsSavingEquipment] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [isLoaded, setLoaded] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);

  const [alertMessage, setAlertMessage] = useState(null);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const handleCloseAlertBox = () => setOpenAlertBox(false);
  const handleOpenAlertBox = async (e, message, id) => {
    setAlertMessage("Are you sure you want to delete this equipment from the database ?")
    setOpenAlertBox(true);
  }

  const handleCloseCreatePackageModal = () => {
    setOpenCreatePackageModal(false);
    setIsUpdating(false)
    nameState("");
    typeState("");
    qtyState("");
    eqState("");
    setDeleteId(null)
    setUpdatingId(null)
  }
  const handleOpenCreatePackageModal = async () => {
    setOpenCreatePackageModal(true);
  };

  const openUpdateView = (e, data) => {
    setIsUpdating(true)
    handleOpenCreatePackageModal(true)
    nameState(data['item_name']);
    typeState(data['item_type']);
    qtyState(data['qty']);
    eqState(data['item_type']);
  }

  const deleteEquipment = async (e, id) => {
    try{
      await services.deleteEquipment(deleteId)
      let _equipments = await services.getItems()
      _equipments = _equipments.data;
      setEquipments(_equipments)
      handleCloseAlertBox()
    }catch(e) {
      console.log(e);
    }
  }

  const createNewEquipment = async (e) => {
    setIsSavingEquipment(true)
    let data = {
      "item_name": `${name}`,
      "item_type": `${type}`,
      "qty": `${qty}`,
      "item_codes_id": `${eq}`,
      "item_code": `${eq}`,
    }
    if (isUpdating == true) {
      try {
        await services.updateEquipment(data, updatingId)

      } catch (error) { }
    } else {
      try {
        await services.registerEquipment(data)

      } catch (error) { }

    }
    let _equipments = await services.getItems()
    _equipments = _equipments.data;
    setEquipments(_equipments)
    handleCloseCreatePackageModal()
    setIsSavingEquipment(false)
  }

  useEffect(() => {
    async function sync() {
      if (services.authStatus == false) {
        navigate('./login', { replace: true });
        return
      } else {
        if (equipments.length == 0) {
          let _equipments = await services.getItems()
          _equipments = _equipments.data;
          setEquipments(_equipments)
        }
        setTimeout(() => {
          // if (isLoaded == false) {
          // if (isLoaded == false) {
          $("#packageTable").DataTable({
            autoWidth: false,
            destroy: true,
            jQueryUI: true,
            // dom: "rBftlip",
            pageLength: 10,
          })
          setLoaded(true);
          // console.log(packa)
          // }
          // }
        }, 100);
      }
    }
    sync()
  }, [equipments])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return isLoaded ? (

    <div>
      <AlertMessage onApprove={deleteEquipment} message={alertMessage} isOpen={openAlertBox} close={handleCloseAlertBox} open={handleOpenAlertBox} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCreatePackageModal}
        onClose={handleCloseCreatePackageModal}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        keepMounted
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCreatePackageModal}>
          <Box sx={style}>
            <form className='popup-form'>
              <div className='package-form-header'>
                {
                  (isUpdating) ? (
                    <p>Update Equipment</p>
                  ) : (
                    <p>Register Equipment</p>
                  )
                }
              </div>
              <div className='p-3 d-flex flex-column'>
                <TextField value={name} onChange={(e) => { nameState(e.target.value) }} id="standard-basic" className='my-2' label="Equipment Name (Cannon DSLR D3000)" variant="standard" />
                <TextField value={type} onChange={(e) => { typeState(e.target.value) }} id="standard-basic" className='my-2' label="Equipment Type (Camera)" variant="standard" />
                <TextField value={qty} onChange={(e) => { qtyState(e.target.value) }} id="standard-basic" className='my-2' type="number" label="Quantity" variant="standard" />
                <div className='d-flex justify-content-start align-items-center'>
                  <TextField value={eq} onChange={(e) => { eqState(e.target.value) }} id="standard-basic" className='my-2 w-100' type="text" label="Equipment Code" variant="standard" />
                  <button onClick={(e)=>{
                    e.preventDefault()
                    eqState(services.generateToken())
                  }} className="btn btn-dark"><MdAutoFixNormal/></button>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center justify-content-end">
                {/* <Button variant="text">Close</Button> */}
                <button type="button" onClick={handleCloseCreatePackageModal} className="btn btn-light btn-sm px-3 text-nowrap mx-3">Cancel</button>
                <button type="button" onClick={createNewEquipment} className="btn btn-dark btn-sm px-3 text-nowrap mx-3">
                  {
                    (isSavingEquipment) ? (
                      <CircularProgress disableShrink style={{ color: "white" }} size={24} />
                    ) : (isUpdating) ? ("Update") : ("Create")
                  }
                </button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
      <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
        <section className='d-flex justify-content-end'>
          <button type="button" onClick={(e) => { handleOpenCreatePackageModal(e); setIsUpdating(false); }} className="btn btn-dark btn-sm px-3 text-nowrap">Create New Equipment</button>
        </section>
        <section className='memo-section'>
          <div className="memo-container pakages">
            <div className="memo-title">Total Equipments</div>
            <div className="memo-data">
              {equipments.length}
            </div>
          </div>
        </section>
      </div>
      {/* <PackageTable tableTitle = "Packages"/> */}
      <table id='packageTable' className='stripe' width="100%">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Date Created</th>
            <th>Type</th>
            <th>Quantity</th>
            <th width="20%">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            equipments.map((equipment, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{equipment['item_name']}</td>
                <td>{new Date(equipment['created_at']).toDateString()}</td>
                <td>{equipment['item_type']}</td>
                <td>{equipment['qty']}</td>
                <td width="20%">
                  <button type="button" onClick={(e) => { setUpdatingId(equipment['id']); openUpdateView(e, equipment) }} className="btn btn-dark  btn-sm px-3 mx-2">Update</button>
                  <button type="button" onClick={(e) => { setDeleteId(equipment['id']); handleOpenAlertBox(e, equipment['id']) }} className="btn btn-danger  btn-sm px-3 mx-2">Delete</button>
                </td>
              </tr>
            )
            )
          }
        </tbody>
      </table>
    </div>
  ) : (<Loader />)
}

export default Equipments
