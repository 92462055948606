import react from 'react';

const Loader = () => {
    return (
        <div className='loader'>
            <div className="logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 822.7 822.6">
                    <title>HaddyProLogo</title>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <path className="cls-4" d="M589.7,439.3a178.49,178.49,0,0,1-39,101.4,177.92,177.92,0,0,0,41.1-101.4Zm-.3-46A180,180,0,0,0,248,351.9a179.56,179.56,0,0,1,338.5,41.4Z" />
                            <path className="cls-3" d="M762,196.1V512.5A358,358,0,0,1,707.1,625c-1.2,1.8,0-499.6,0-499.6a411.29,411.29,0,0,0-596.3,5.2V618.1a3.74,3.74,0,0,1-.6-.7A361.61,361.61,0,0,1,56,494.7V204.3a408.28,408.28,0,0,0-56,207C0,638.5,184.2,822.6,411.3,822.6c148.3,0,278.3-78.4,350.7-196.1a411.82,411.82,0,0,0,0-430.4ZM411.3,776.2a361.79,361.79,0,0,1-252-101.4V439.3h72.1V658.8h43.9V546.5a179.69,179.69,0,0,0,270,.6,180.43,180.43,0,0,1-133,58.5c-99.4,0-180.2-80.6-180.2-180a178.73,178.73,0,0,1,11-61.9,163.66,163.66,0,0,0-8.6,29.7H159.3V147.6a364.15,364.15,0,0,1,495.2-8.2V393.3H589.3a179.36,179.36,0,0,1,3,32.2,125.23,125.23,0,0,1-.6,13.8h62.8V681.1h2.2A362.44,362.44,0,0,1,411.3,776.2Z" />
                            <path className="cls-1" d="M589.4,393.3h-2.8a179.56,179.56,0,0,0-338.5-41.4c-1,2.1-1.9,4.2-2.7,6.4a46,46,0,0,0-2.2,5.3,178.73,178.73,0,0,0-11,61.9c0,99.4,80.7,180,180.2,180a180.6,180.6,0,0,0,133-58.5c1.8-2.1,3.6-4.2,5.3-6.4a177.79,177.79,0,0,0,39-101.4h2.1c.4-4.5.6-9.2.6-13.8A180,180,0,0,0,589.4,393.3ZM412.3,285a140.5,140.5,0,1,1-137,172.5V393.4A140.82,140.82,0,0,1,412.3,285Z" />
                            <path className="cls-2" d="M405.9,135.7c-161.6,0-292.7,131-292.7,292.7S244.3,721.1,405.9,721.1s292.8-131,292.8-292.7S567.6,135.7,405.9,135.7Zm0,539.1c-136,0-246.3-110.3-246.3-246.3S269.9,182.2,405.9,182.2,652.2,292.5,652.2,428.5,541.9,674.8,405.9,674.8Z" />
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    );
}

export default Loader;