import React, { useEffect, useState } from 'react'
import Loader from '../includes/Loader';
import { useHistory } from "react-router-dom";
import Services from '../../services.js';
import { $ } from 'react-jquery-plugin';
import { useNavigate, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CircularProgress, Fade, Modal, Switch, TextField } from '@mui/material';
import { logRoles } from '@testing-library/react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FaFileUpload } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Button } from 'bootstrap';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import set from 'date-fns/set';
import { AlertMessage } from '../includes/modals';
import DataTable from '../includes/tabledt';
// ////////////////////////////////////////////////////////////////////////////// //

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const toEnv = () => {
    setTimeout(() => {
        $("#packageTable").DataTable({
            autoWidth: false,
            destroy: true,
            jQueryUI: true,
            // dom: "rBftlip",
            order: [[1, 'asc']],
            pageLength: 10,
        })
    }, 100);
}

function a11yProps(index) {
    // if (index == 1) toEnv()
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#1A2027',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
}));
// ////////////////////////////////////////////////////////////////////////////// //

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};


const Settings = () => {
    let services = new Services()
    const [orders, setOrders] = useState([]);
    const [sysinfo, setSysinfo] = useState([
        {
            name: "System Name",
            value: "Haddypro CRM",
        },
        {
            name: "System Version",
            value: "V1.0",
        },
        {
            name: "System Build",
            value: "6822001",
        },
        {
            name: "System Email",
            value: "no-reply@haddyprotz.com",
        }
    ]);
    const [isLoaded, setLoaded] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isClearingCache, setIsClearingCache] = useState(false);
    const [environmentVariables, setEnvironmentVariables] = useState([]);
    const [envName, setEnvName] = useState("");
    const [envValue, setEnvValue] = useState("");
    const [deletingId, setDeletingId] = useState(null);
    const [updateId, setUpdateId] = useState(null);
    const [isPublic, setIsPublic] = useState(true);
    const [isPublicId, setIsPublicId] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const clearCaches = async () => {
        setIsClearingCache(true)
        await services.clearCache()
        setIsClearingCache(false)
    }

    const [servicesList, setServices] = useState([]);
    const [openCreateEnvVar, setOpenCreateEnvVar] = useState(false);

    const handleCloseEnvVar = () => {
        setOpenCreateEnvVar(false);
        setEnvName("")
        setEnvValue("")
        setUpdateId(null)
    }
    const handleOpenEnvVar = async () => {
        setOpenCreateEnvVar(true);
    }
    const handleOpenUpdateEnvVar = async (data) => {
        setOpenCreateEnvVar(true);
        setUpdateId(data['id'])
        setEnvName(data['variable_name']);
        setEnvValue(data['value']);
    }

    const [alertMessage, setAlertMessage] = useState(null);
    const [openAlertBox, setOpenAlertBox] = useState(false);
    const handleCloseAlertBox = () => setOpenAlertBox(false);
    const handleOpenAlertBox = async (e, message, id) => {
        setAlertMessage(message)
        setOpenAlertBox(true);
        setDeletingId(id)
    }

    const handleCreateNewVariable = async () => {
        handleOpenEnvVar()
    }

    const saveEnvironment = async () => {
        setIsUpdating(true)
        let data = {
            variable_name: envName,
            value: envValue,
        }
        try {
            if (updateId == null) {
                let response = await services.registerEnvironment(data)
            } else {
                let response = await services.updateEnvironment(data, updateId)
            }
            console.log(response)
        } catch (error) { }
        let response = await services.getEnvVars();
        console.log(response.data)
        setEnvironmentVariables(response.data)
        setIsUpdating(false)
        handleCloseEnvVar()
        setEnvName("")
        setEnvValue("")
    }

    const deleteEnv = async () => {
        setIsUpdating(true)
        try {
            let response = await services.deleteEnvironment(deletingId)
            console.log(response)
        } catch (error) { }
        setIsUpdating(false)
        setDeletingId(null)
        let _response = await services.getEnvVars();
        setEnvironmentVariables(_response.data)
        handleCloseAlertBox()
    }

    const handlePublicChange = async (e) => {
        let data = {
            "variable_name": "is_price_public",
            "value": e.target.checked.toString()
        }
        await services.updateEnvironment(data, isPublicId)
        console.log(e.target.checked.toString())
    }
    const columns = [
        { field: 'varname', headerName: 'Key / Variable Name' },
        { field: 'value', headerName: 'Value' },
        { field: 'actions', headerName: 'Actions' },
    ];
    useEffect(() => {
        const sync = async () => {
            let response = await services.getEnvVars();
            let isPublic = false;
            let _data = [];
            for (let i = 0; i < response.data.length; i += 1) {
                let item = response.data[i]
                _data.push({
                    varname: item['variable_name'],
                    value: item['value'],
                    actions: (<td width="25%">
                        <button type="button" onClick={(e) => { handleOpenUpdateEnvVar(item) }} className="btn btn-dark btn-sm px-3 m-2 text-nowrap">Update</button>
                        <button type="button" onClick={(e) => { handleOpenAlertBox(e, `Are you sure you want to delete ${item['variable_name']} From Environment Variables ?`, item['id']) }} className="btn btn-danger  btn-sm px-3 mx-2">Delete</button>
                    </td>)
                })
                if (item['variable_name'] == "is_price_public") {
                    isPublic = item['value']
                    setIsPublicId(item['id'])
                }
            }
            setIsPublic(isPublic == "true")
            setEnvironmentVariables(_data)
        }
        sync()
    }, [])


    return !isLoaded ? (
        <div>
            <AlertMessage onApprove={deleteEnv} message={alertMessage} isOpen={openAlertBox} close={handleCloseAlertBox} open={handleOpenAlertBox} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openCreateEnvVar}
                onClose={handleCloseEnvVar}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                keepMounted
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openCreateEnvVar}>
                    <Box sx={style}>
                        <form className='popup-form'>
                            <div className='package-form-header'>
                                {
                                    (updateId == null) ? (
                                        <p>Env</p>
                                    ) : (
                                        <p>Env Update</p>
                                    )
                                }
                            </div>
                            <div className='p-3 d-flex flex-row'>
                                <TextField value={envName} onChange={(e) => { setEnvName(e.target.value) }} id="standard-basic" type="text" className='m-2' label="Var Name" variant="standard" />
                                <TextField value={envValue} onChange={(e) => { setEnvValue(e.target.value) }} id="standard-basic" type="text" className='m-2' label="Value" variant="standard" />
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-end">
                                {/* <Button variant="text">Close</Button> */}
                                <button type="button" onClick={handleCloseEnvVar} className="btn btn-light btn-sm px-3 text-nowrap mx-3">Cancel</button>
                                <button type="button" onClick={saveEnvironment} className="btn btn-dark btn-sm px-3 text-nowrap mx-3">
                                    {(isUpdating) ? (
                                        <CircularProgress disableShrink style={{ color: "white" }} size={24} />
                                    ) : (
                                        (updateId == null) ? ("Save") : ("Update")
                                    )}
                                </button>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
            <div className='d-flex flex-row-reverse justify-content-between align-items-start my-3'>
                {/* <section className='d-flex justify-content-center align-items-center'> */}
                <div class="m-auto alert alert-danger" role="alert">
                    NOTE: DO NOT CHANGE ANYTHING IN SETTINGS IF YOU DON'T KNOW WHAT YOU ARE DOING!
                </div>
                {/* </section> */}
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Theme Setup" {...a11yProps(0)} />
                        <Tab label="Environment Variables" {...a11yProps(1)} />
                        <Tab label="Advanced" {...a11yProps(2)} />
                        <Tab label="System Info" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <form action="" className='web-update-form bg-light border-2 d-flex flex-column p-3'>
                        <p className='m-auto'><i>Future Update</i></p>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <section className='d-flex justify-content-end'>
                        <button type="button" onClick={handleCreateNewVariable} className="btn btn-dark btn-sm mb-3 px-3 text-nowrap">New Environment Var</button>
                    </section>
                    <form action="" className='web-update-form bg-light border-2 d-flex flex-column p-3 my-3'>
                        <FormControlLabel control={<Switch onChange={(e) => { setIsPublic(e.target.checked); handlePublicChange(e); }} checked={isPublic} />} label="Display Price On The Website" />
                    </form>
                    <form action="" className='web-update-form bg-light border-2 d-flex flex-column p-3'>
                        {/* <table id='packageTable' className='table stripe' width="100%">
                            <thead>
                                <tr>
                                    <th>Key / Variable Name</th>
                                    <th>Value</th>
                                    <th width="20%">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    environmentVariables.map((env, index) => {
                                        let key = Object.keys(env)[0]
                                        return (
                                            <tr>
                                                <td>{env['variable_name']}</td>
                                                <td>{env['value']}</td>
                                                <td width="25%">
                                                    <button type="button" onClick={(e) => { handleOpenUpdateEnvVar(env) }} className="btn btn-dark btn-sm px-3 m-2 text-nowrap">Update</button>
                                                    <button type="button" onClick={(e) => { handleOpenAlertBox(e, `Are you sure you want to delete ${env['variable_name']} From Environment Variables ?`, env['id']) }} className="btn btn-danger  btn-sm px-3 mx-2">Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table> */}
                        <DataTable columns={columns} rows={environmentVariables} />
                    </form>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <form action="" className='web-update-form bg-light border-2 d-flex justify-content-start align-items-start flex-column p-3'>
                        {
                            (isClearingCache) ? (
                                <CircularProgress disableShrink style={{ color: "white" }} size={24} />
                            ) : (
                                <button type="button" onClick={clearCaches} className="btn btn-dark btn-sm px-3 text-nowrap">Clear System Caches</button>
                            )
                        }
                    </form>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <div className='d-flex flex-column'>
                        <table>
                            {
                                sysinfo.map((info, index) => {
                                    return (
                                        <tr>
                                            <td>{info['name']}</td>
                                            <td><b>{info['value']}</b></td>
                                        </tr>
                                    )
                                })
                            }

                            {/* <div className='d-flex flex-row my-1 align-items-center'>
                                <p>{info['name']}:</p>
                                <Item>{info['value']}</Item>
                                <p className='mx-3 bg-light p-2 fw-bold'>{info['value']}</p>
                            </div> */}
                        </table>
                    </div>
                </TabPanel>
            </Box>
        </div>
    ) : (<Loader />)
}

export default Settings
